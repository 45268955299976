import { ErrorLog, PlanData, SESSION_SEC, UserData } from "../shared";

export class UserModel {
  public static readonly instance = new UserModel();
  static events = {
    loginUpdate: "login_update",
    userDataUpdate: "user_data_update",
    errorsChanged: "errors_changed",
  };

  public loginExpiresAt: Date | undefined;
  public loginData: { email: string; password: string } | undefined;
  public didSetup: boolean = false;
  public fetching: boolean = false;
  public env: "production"|"development" | undefined = undefined;
  private user: UserData | undefined;
  public readonly errorLogs: Array<ErrorLog> = [];

  public addErrorLog(msg: string) {
    this.errorLogs.push({ msg, timestamp: Date.now() });
    document.dispatchEvent(
      new CustomEvent(UserModel.events.errorsChanged, {
        detail: this.errorLogs,
      })
    );
  }

  public removeErrorLog(data?: ErrorLog) {
    if (!data) {
      this.errorLogs.splice(0, this.errorLogs.length);
    } else {
      const index = this.errorLogs.indexOf(data);
      if (index === -1) {
        return;
      }
      this.errorLogs.splice(index, 1);
    }
    document.dispatchEvent(
      new CustomEvent(UserModel.events.errorsChanged, {
        detail: this.errorLogs,
      })
    );
  }

  public getUser(): UserData | undefined {
    return this.user;
  }

  public isGuestUser(): boolean {
    return this.user?.guest ? true : false;
  }

  public addLastQuery(query: string) {
    if (!this.user?.lastQueries) {
      this.user!.lastQueries = [];
    }
    this.user!.lastQueries.push(query);
  }

  public hasValidLogin(): boolean {
    return (
      this.loginExpiresAt !== undefined &&
      this.loginExpiresAt > new Date() &&
      this.user !== undefined
    );
  }

  public updateData(pars: { email: string; company: string }) {
    this.user!.email = pars.email;
    this.user!.company = pars.company;
    document.dispatchEvent(
      new CustomEvent(UserModel.events.userDataUpdate, { detail: this.user! })
    );
  }

  public updatePlan(plan: PlanData) {
    this.user!.plan = plan;
    document.dispatchEvent(
      new CustomEvent(UserModel.events.userDataUpdate, { detail: this.user! })
    );
  }

  public prolongLoginExpiresAt() {
    if (!this.hasValidLogin()) {
      return;
    }
    if (this.user) {
      this.setLogin(this.user!);
    }
  }

  public logout() {
    if (!this.hasValidLogin()) {
      this.user = undefined;
      this.loginExpiresAt = undefined;
      return;
    }
    this.user = undefined;
    this.loginExpiresAt = undefined;
    console.log("UserModel.logout ");
    document.dispatchEvent(new CustomEvent(UserModel.events.loginUpdate));
  }
  public setLogin(user: UserData) {
    //console.log("UserModel.setLogin ", user);
    this.user = user;
    //const hadLogin = this.hasValidLogin();
    const secLeft = SESSION_SEC;
    this.loginExpiresAt = new Date(new Date().getTime() + secLeft * 1000);
    document.dispatchEvent(new CustomEvent(UserModel.events.loginUpdate));
  }
}
