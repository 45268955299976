import myFetch, { dispatchResponseError } from "./Helper";
import { Routes } from "../shared/Routes";
import { ScanJobsModel } from "../models/ScanJobsModel";
import { PlanData, ScanJob, ScanOperation } from "../shared";
import { UserModel } from "../models/UserModel";
import { CheckPlanLimitCmd } from "./CheckPlanLimitCmd";
import { ConfirmRequestCmd } from "./ConfirmRequestCmd";

export default class ScanOperationCmd {
  public async run(
    scanJobId: string,
    operation: ScanOperation
  ): Promise<ScanJob | undefined> {
    
    if (operation === "rescan") {
      if (!(await new CheckPlanLimitCmd().run())) {
        return;
      }
    }

    if(operation==="delete") {
      const msg="Are you sure you want to delete this scan? This action can't be undone.";
      const confirmDelete=await (new ConfirmRequestCmd().run(msg));
      if(!confirmDelete) {
        return;
      }
    }

    const route = Routes.scanjoboperation;
    const response = await myFetch(route, { scanJobId, operation });
    if (!response) {
      return;
    }
    if (operation === "download") {
      const scanJob = ScanJobsModel.instance.getScanJob(scanJobId)!;
      const pdfBlob = await response.blob();
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Create a link and trigger the download
      const downloadLink = document.createElement("a");
      downloadLink.href = pdfUrl;
      downloadLink.download = scanJob.fileName;
      downloadLink.click();
      return scanJob;
    }

    const json = await response.json();
    if (json.error) {
      dispatchResponseError(json.error);
    }
    if (json.data) {
      if (operation === "rescan") {
        const newScanJob = json.data.scanJob as ScanJob;
        ScanJobsModel.instance.addScanJobs([newScanJob]);
        const updatedPlan = json.data.plan as PlanData;
        UserModel.instance.updatePlan(updatedPlan);
        return newScanJob;
      }
      if (operation === "delete") {
        ScanJobsModel.instance.deleteScanJob(scanJobId);
      }
    }
  }
}
