/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Colors,
  Divider,
  Menu,
  MenuItem,
  Popover,
  Spinner,
} from "@blueprintjs/core";
import { QueryJob } from "../../shared";
import DeleteQueryJobCmd from "cmd/DeleteQueryJobCmd";
import { QueryObjectWrapper } from "./QueryObjectWrapper";
import { useState } from "react";

function queryHasContents(data: QueryJob) {
  if (!data.result) {
    return false;
  }
  if (data.result.length === 0) {
    return false;
  }
  //console.log("queryHasContents", data.result);
  if (data.result.length === 1 && data.result[0].trim().length < 2) {
    return false;
  }
  return true;
}

function ErrorInfo() {
  //describe how to write a proper invoice prompt
  return (
    <div>
      <div style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}>
        <b>Error</b>
        <div>
          Use only prompts that refer to your uploaded invoices. Avoid using
          prompts that refer to other data sources. Also, avoid using multiple
          prompts in the same query.
        </div>
      </div>
    </div>
  );
}

export function QueryRow(pars: { data: QueryJob; index: number }) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  let body: JSX.Element = <div>...</div>;
  let hasBody = false;

  const onReadMore = () => {
    setIsOpen((prev) => {
      return !prev;
    });
  };

  if (pars.data.queryState === "error") {
    body = (
      <div
        style={{ textAlign: "center", fontStyle: "italic", display: "block" }}
      >
        <Button
          text={pars.data.error ? pars.data.error : "ERROR"}
          rightIcon={isOpen ? "caret-up" : "caret-down"}
          minimal
          small
          onClick={onReadMore}
        />
        {isOpen && <ErrorInfo></ErrorInfo>}
      </div>
    );
    hasBody = true;
  }
  if (pars.data.queryState === "done") {
    const rows = pars.data.result?.map((r, i) => {
      return <QueryResult log={r} key={i} />;
    });
    if (rows) {
      body = <div>{rows}</div>;
      hasBody = queryHasContents(pars.data);
    }
  }
 
  if (pars.data.queryState === "processing" || pars.data.queryState === "pending" || pars.data.queryState === "running") {
    body = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Spinner size={15}></Spinner>
        <div style={{marginLeft:5, textAlign: "center", fontStyle: "italic" }}>{pars.data.queryState} ... (30 sec on average)</div>
      </div>
    );
    hasBody = true;
  }


  if (!hasBody) {
    body = NoResultDiv();
    hasBody = true;
  }

  let msg = "";
  if (pars.data.adjustedMsg && pars.data.adjustedMsg.length > 0) {
    msg = pars.data.adjustedMsg;
  } else if (pars.data.originalMsg && pars.data.originalMsg.length > 0) {
    msg = pars.data.originalMsg;
  } else {
    const a = (pars.data as any).msg;
    if (a && a.length > 0) {
      msg = a;
    }
  }

  return (
    <div style={{ display: "block" }}>
      <div style={{ color: Colors.GRAY2, textAlign: "center", fontSize: 12 }}>
        {new Date(pars.data.createdTimestamp * 1000).toLocaleString()}
      </div>
      <div style={{ display: "flex", marginLeft: 20, paddingBottom: 5 }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {msg}
        </div>
        <DeleteButton data={pars.data} />
      </div>
      <div
        style={{
          margin: 0,
          padding: 5,
          backgroundColor: Colors.LIGHT_GRAY5,
          borderRadius: 10,
        }}
      >
        <div>{body}</div>
      </div>

      <div style={{ height: 5 }}></div>
      <Divider></Divider>
    </div>
  );
}

function NoResultDiv() {
  return (
    <div
      key={"NoResultDiv"}
      style={{ display: "block", textAlign: "center", fontStyle: "italic" }}
    >
      No result
    </div>
  );
}

export function QueryResult(pars: { log: string }) {
  if (pars.log.length < 5) {
    return NoResultDiv();
  }
  const list = JSON.parse(pars.log) as Array<any>;
  return QueryObjectWrapper(list);
}

function DeleteButton(props: { data: QueryJob }) {
  const onDelete = () => {
    new DeleteQueryJobCmd().run(props.data._id).catch(console.error);
  };

  return <Button icon="trash" minimal onClick={onDelete} />;
}

function DetailsButton(props: { data: QueryJob }) {
  const onDelete = () => {
    new DeleteQueryJobCmd().run(props.data._id).catch(console.error);
  };
  const onDetails = () => {};
  const onRefresh = () => {};

  const renderMenu = () => {
    return (
      <Menu>
        <MenuItem onClick={onDetails} icon="document" text="Details" />

        <MenuItem onClick={onDetails} icon="download" text="Download PDF" />

        <MenuItem onClick={onRefresh} icon="refresh" text="Run again" />

        <MenuItem onClick={onDelete} icon="delete" text="Delete query" />
      </Menu>
    );
  };

  return (
    <Popover content={renderMenu()} placement="bottom">
      <Button icon="more" minimal />
    </Popover>
  );
}
