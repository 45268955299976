export const Routes = {
  server_prod: "https://srv.clickbot.pro:8100",
  webserver_prod: "https://invoice-prompt.com",

  server_dev: "http://localhost:8100",
  webserver_dev: "http://localhost:3000",

  index: "/",
  share: "/share/:shareId",
  signup: "/signup",
  signupguest: "/signupguest",
  login: "/login",
  deleteaccount: "/deleteaccount",
  addlogmsg: "/addlogmsg",
  logout: "/logout",
  hasUser: "/hasuser",
  getuser: "/getuser",
  scanjoboperation: "/scanjoboperation",
  invoiceoperation: "/invoiceoperation",
  updateuserdata: "/updateuserdata",
  saveuser: "/saveuser",
  upgradeplan: "/upgradeplan",

  deletescanjob: "/deletescanjob",
  deletequeryjob: "/deletequeryjob",
  getqueryjobs: "/getqueryjobs",
  getallinvoices: "/getallinvoices",
  getusercanjobs: "/getusercanjobs",
  addnewscanjob: "/addnewscanjob",
  deleteallinvoices: "/deleteallinvoices",

  queryinvoices: "/queryinvoices",
  assigntags: "/assigntags",
  submitemail: "/submitemail",

} as const; // as const makes this a read-only object



