import { AllScanJobs } from "../shared";
import React, { useEffect } from "react";
import DropInvoice from "./DropInvoice";
import { ScanJobsModel } from "../models/ScanJobsModel";
import { Button, Colors, Divider } from "@blueprintjs/core";
import GetScanJobsCmd from "../cmd/GetScanJobsCmd";

export default function ScanJobsPart() {
  const [scanJobs, setScanJobs] = React.useState<AllScanJobs>({
    ...ScanJobsModel.instance.getScanJobs(),
  });

  useEffect(() => {
    const onScanJobUpdated = (e: Event) => {
      const ce = e as CustomEvent;
      setScanJobs({ ...(ce.detail as AllScanJobs) });
    };
    document.addEventListener(
      ScanJobsModel.events.scanJobsChanged,
      onScanJobUpdated
    );
    return () => {
      document.removeEventListener(
        ScanJobsModel.events.scanJobsChanged,
        onScanJobUpdated
      );
    };
  }, []);


  return (
    <div style={{ textAlign: "center" }}>
      <DropInvoice></DropInvoice>
    </div>
  );
}
