import { Dialog, DialogBody } from "@blueprintjs/core";
import { useEffect, useState } from "react";

export function TermsAndConditions() {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    const onShow = (_e: Event) => {
      setVisible(true);
    };
    document.addEventListener("TermsAndConditions", onShow);
    return () => {
      document.removeEventListener("TermsAndConditions", onShow);
    };
  }, []);

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Dialog
      style={{ width: 600 }}
      isOpen={visible}
      title="Terms and conditions"
      icon="info-sign"
      canOutsideClickClose
      onClose={onClose}
      onClosed={() => {}}
    >
      <DialogBody>
        <Content />
      </DialogBody>
    </Dialog>
  );
}

function Content() {
  console.log("Terms Content render");
  return (
    <div>
      General Conditions of Use ("GCU")** apply to any access and/or use of the
      Invoice-Prompt web platform ("the Platform").
      <br></br>
      The Preamble is an integral part of these GCU.
      <br></br>
      <h3>Preamble</h3>
      <br></br>
      The use of the Platform, whether in its free version (Free Trial) or paid
      (when the customer has a paid access account to the Platform), is subject
      to these GCU, which the user (hereinafter, the "user") declares to have
      read, understood, and accepted.
      <br></br>
      Similarly, the use of the Platform implies acceptance of the General
      Contracting Conditions (GCC) which will be carried out at the time of
      Subscription by the Account Creator or its Administrator.
      <br></br>
      The aforementioned General Contracting Conditions prevail over these GCU.
      <br></br>
      Invoice-Prompt reserves the right to modify or adapt these GCU at any
      time, notifying each new version to the customer, who commits to being
      aware of the changes made.
      <h2>Article 1 — Access to the Invoice-Prompt Platform</h2>
      <h3>1.1 General</h3>
      Invoice-Prompt only allows access to the Platform to those specifically
      authorized by Invoice-Prompt and its customers.
      <br></br>
      Invoice-Prompt commits to implementing all necessary means to allow the
      user rapid and reliable access to the Platform. A support service, in the
      case of using the Platform through a paid contract, is available to the
      customer under the terms agreed upon in the type of service subscribed.
      <br></br>
      The user acknowledges that the Platform is a very complex tool, mainly in
      terms of computer technology, and commits to bearing the risks that may
      exist due to inadequacy or unavailability of the Platform.
      <br></br>
      Invoice-Prompt may recommend the user to use specific technical parameters
      or certain configurations to use the Platform. The user is responsible for
      following the implementation of these technical parameters or
      recommendations and ensuring that their equipment is compatible with the
      Platform.
      <br></br>
      The user is solely responsible for their internet connection, as well as
      all related expenses. Similarly, they acknowledge that Invoice-Prompt
      cannot be held responsible in case of internet interruption, viruses
      affecting their data or programs, massive use of access passwords to their
      Account, and, in general, all damages caused by third parties.
      <h3>
        1.2 "Free Trial" Account as a possibility prior to subscribing to a Paid
        Plan
      </h3>
      Invoice-Prompt offers the possibility to create a "Free Trial" account for
      potential future customers who want to test the Platform beforehand.
      <br></br>
      This free trial account is made available to users for a period of fifteen
      (15) days from its subscription under the following conditions:
      <br></br>- Only one Free Trial Account can be created, which cannot be
      renewed.
      <br></br>- Invoice-Prompt may, at its discretion and if requested by the
      user, allow the user to use the Free Trial Account for an additional
      15-day period.
      <br></br>- The user authorized to use a Free Trial Account acknowledges
      that access to the Platform is solely for testing the Platform's
      functionalities before a possible subscription to the Paid Service Plan.
      It is expressly prohibited to carry out treatments or services for third
      parties using the Free Trial Account.
      <br></br>- Invoice-Prompt's liability will not be compromised in any way
      or under any concept during the user's use of the Platform during the free
      period. Invoice-Prompt will not be liable for any compensation, of any
      kind, arising from the malfunction of the Platform during the trial
      period.
      <br></br>
      Invoice-Prompt reserves the right to delete without prior notice any Free
      Trial Account that does not comply with the preceding provisions or any
      other regulated in these GCU or in the documents signed between the
      parties.
      <br></br>
      If the Free Trial is not followed by the Subscription of a Paid Service
      Plan, Invoice-Prompt will delete the Free Trial Account, as well as the
      associated data within twelve (12) months from the closure of the Free
      Trial Account.
      <br></br>
      Once the Paid Service Plan is validated by the customer, a confirmation of
      the service contract, as well as the invoice (hereinafter, "Contract
      Confirmation"), will be sent to them by email.
      <h2>Article 2 — Use of the Platform</h2>
      The user commits to making appropriate and lawful use of the Platform and
      Website, in accordance with applicable legislation and as provided in this
      document.
      <br></br>
      Good use includes, but is not limited to, the following actions that the
      user must carry out:
      <br></br>- Always provide accurate information, which must be updated as
      necessary to remain accurate.
      <br></br>- Use the Platform according to its purpose and cooperate in good
      faith with Invoice-Prompt.
      <br></br>- Implement the technical requirements and/or recommendations
      issued by Invoice-Prompt.
      <br></br>- Not share access to the Platform, as this right is strictly
      personal to the user.
      <br></br>- Respect the rights of third parties and, in general, the laws
      and regulations in force related to the use of the Platform.
      <br></br>- Refrain from introducing malicious, derogatory, obscene,
      defamatory, deliberately misleading, illegal, and/or immoral messages,
      information, or data.
      <br></br>- Not alter the integrity or performance of the Platform or the
      data contained therein.
      <br></br>- Not attempt to gain unauthorized access to the Platform or its
      associated systems or networks or intercept data.
      <br></br>- Not harm Invoice-Prompt's reputation, denigrate the Website and
      the Platform, or defame Invoice-Prompt, especially on the Internet,
      including social networks, acting with moderation and caution when making
      statements about Invoice-Prompt or its employees.
      <br></br>- Not engage in any conduct that may harm Invoice-Prompt or allow
      users to access and use the Website and the Platform without
      authorization, even through the use of viruses, codes, malicious programs,
      or files.
      <br></br>- Not copy or sell the Website and the Platform in whole or in
      part or exploit them for commercial purposes.
      <br></br>- Not commit crimes or encourage others to do so.
      <br></br>- Not send unsolicited advertising or promotional materials
      ("spam").
      <br></br>
      In case of non-compliance with the above, Invoice-Prompt reserves the
      right to immediately suspend access to the customer's Account without
      compensation and without prior notice. The Website may also contain links
      to other websites not operated by Invoice-Prompt (hereinafter 'Other
      Websites').
      <br></br>
      Invoice-Prompt has no control over Other Websites and is not responsible
      for their content or any damage they may cause by using them. However, it
      reserves the right to remove such links if it becomes known that any
      irregularity or illegality is committed on the linked site.
      <h2>Article 3 — Responsibilities</h2>
      Invoice-Prompt implements the necessary technical measures to ensure the
      security of connections, content, and Personal Data of customers or users.
      Invoice-Prompt provides the right to use the Platform, constituting an
      obligation of means. However, to date, tests and experiences may not cover
      all possible uses.
      <br></br>
      Invoice-Prompt cannot guarantee that the Platform will operate without any
      interruptions or malfunctions, or that there will be no anomalies or
      errors, which can be corrected. It may be the case that the Platform is
      not compatible with the user's equipment or that there is a configuration
      different from those expressly approved by Invoice-Prompt.
      <br></br>
      The user or customer will be solely responsible for:
      <br></br>- Their access and use of the Platform, as well as damages caused
      by viruses on their equipment.
      <br></br>- Obtaining and maintaining all the equipment necessary to access
      the Platform, ensuring that this equipment is compatible with it.
      <br></br>- Errors that occur due to their providers.
      <br></br>
      Under no circumstances will Invoice-Prompt be responsible for the content
      hosted on the Platform regarding third-party Final Providers or their
      services.
      <br></br>
      Invoice-Prompt reserves the right to interrupt access to the Platform at
      any time in the following cases:
      <br></br>- To perform an update, carry out technical maintenance,
      improvement, and/or repair interventions that contribute to its proper
      functioning. Invoice-Prompt will do its best to notify the user of these
      interventions.
      <br></br>- In case of server unavailability for any reason.
      <br></br>- In case of non-payment, non-compliance with these conditions
      and/or the GCC, actions by third parties that may affect the security of
      the Platform and Invoice-Prompt's content.
      <br></br>
      In case of temporary interruption of the Platform for the reasons
      mentioned above, Invoice-Prompt will not be affected in terms of paying
      any compensation to users or customers.
      <h2>Article 4 — Intellectual Property</h2>
      Invoice-Prompt, as the holder of all rights to the Platform, holds all
      intellectual and industrial property rights to it.
      <br></br>
      Invoice-Prompt expressly reserves the exploitation rights it holds over
      the licensed Platform, expressly including reproduction, transformation,
      public communication, and distribution rights in all possible forms of
      exploitation.
      <br></br>
      The website https://invoice-prompt.com and all URLs where
      Invoice-Prompt offers its services are domains registered by
      Invoice-Prompt. Likewise, Intellectual Property rights related to the
      software and the content of the Website belong to Invoice-Prompt or the
      grantors of its licenses.
      <br></br>
      Consequently, the total or partial reproduction of the Website is
      expressly prohibited. It is also not allowed to publish, manipulate, or
      distribute the content of the Website without the express authorization of
      Invoice-Prompt or the legitimate owner, if applicable.
      <br></br>
      <h2>Article 5 — Resolution</h2>
      In case of non-compliance by the user with these GCU, Invoice-Prompt
      reserves the right to delete access to their account or delete it.
      <br></br>
      Similarly, Invoice-Prompt reserves the right to take any legal action that
      may be relevant to ensure the user ceases inappropriate actions against
      Invoice-Prompt's interests, reserving any action to obtain the
      corresponding compensation.
      <br></br>
      <h2>Article 6 — Protection of Personal Data</h2>
      <br></br>
      Invoice-Prompt collects and processes data that customers voluntarily
      provide to access the Platform and use it, as well as data related to user
      preferences and traffic, in accordance with its Privacy Policy and Cookie
      Policy.
      <br></br>
      In accordance with the Privacy Policy, the information collected by
      Invoice-Prompt is subject to computer processing mainly aimed at managing
      the Platform, the contracted service plans, the provision of said services
      and their support, the development of commercial and marketing offers, as
      well as to strengthen and improve products, content, and the experience of
      customers or users.
      <br></br>
      In accordance with the regulations in force applicable to personal data
      protection, the customer has the rights of access, rectification,
      deletion, limitation, and portability of data and, if applicable,
      opposition to their processing. The customer can exercise their rights at
      the following address dev@invoice-prompt.com, justifying at all
      times their identity or that of their representative; in the latter case,
      the representative must present the power granted by the customer.
      <br></br>
      <h2>Article 7 — Applicable Law</h2>
      <br></br>
      These GCU are governed by German law.
      <br></br>
      To resolve any doubt or disagreement that may arise between the parties
      regarding the fulfillment or interpretation of the clauses of this
      document, both parties, by mutual agreement, submit to the Jurisdiction
      and competence of the Courts and Tribunals of the city of Hamburg, waiving
      their own jurisdiction if it were different.
      <br></br>
      In the hypothetical case that there are translations in different
      languages of these GCU, in case of conflict between them, the German or
      English version will prevail.
      <br></br>
      Hamburg, November 2023.
    </div>
  );
}
