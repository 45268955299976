import { PlanName, Routes } from "shared";
import myFetch, { dispatchResponseError } from "./Helper";

export class SubmitUnavailablePlanCmd {
  async run(pars: { email: string; planName: PlanName }):Promise<boolean> {
    const route = Routes.submitemail;

    const response = await myFetch(route, pars);
    if (!response) {
      return false;
    }
    const json = await response.json();
    if (json.error) {
      dispatchResponseError(json.error);
    }
    if(json.data) {
      return true;
    }
    return false;
  }
}
