import myFetch, { dispatchResponseError } from "./Helper";
import { Routes } from "../shared/Routes";
import { QueryJobsModel } from "../models/QueryJobsModel";

export default class DeleteQueryJobCmd {
  public async run(queryJobId?: string): Promise<void> {
    if (typeof queryJobId!=="undefined") {
      QueryJobsModel.instance.removeQueryJob(queryJobId);
    } else {
      QueryJobsModel.instance.setQueryJobs([]);
    }
    const route = Routes.deletequeryjob;
    const response = await myFetch(route, { queryJobId });
    if (!response) {
      return;
    }
    const json = await response.json();
    if (json.error) {
      dispatchResponseError(json.error);
    }
    if (json.data) {
      //
    }
  }
}
