import { Button, Colors, ProgressBar, Text } from "@blueprintjs/core";
import { ScanJob } from "../../shared";
import React, { useEffect } from "react";
import ScanOperationCmd from "cmd/ScanOperationCmd";
import { dispatchResponseError } from "cmd/Helper";

export function ScanRow(pars: { data: ScanJob }) {

  console.log("ScanRow filename ", pars.data.fileName,pars.data);
  const [loading, setLoading] = React.useState(false);
  const scanJobId = pars.data._id; 

  const onButtonDeleteJob = async () => {
    setLoading(true);
    await new ScanOperationCmd().run(scanJobId, "delete");
    setLoading(false);
  };

  const onButtonDownload = () => {
    setLoading(true);
    new ScanOperationCmd()
      .run(scanJobId, "download")
      .then(setLoading.bind(null, false))
      .catch(console.error);
  };

  const canDownload =
    pars.data.progressState === "error" ||
    pars.data.progressState === "limit_reached" ||
    pars.data.progressState === "cancelled" ||
    pars.data.progressState === "progressing";

  return (
    <div
      style={{
        display: "block",
        border: "2px solid",
        borderRadius: 10,
        padding: 5,
        margin: 5,
        boxShadow: "2px 2px 2px 0px rgba(0,0,0,0.2)",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <div style={{ width: "20%",textAlign:"left" }}>
          <Text style={{ marginRight: 5, marginLeft: 5 }}>
            {pars.data.fileName}
          </Text>
        </div>

        <div style={{ width: "35%" }}>
          <Text style={{ marginRight: 5, marginLeft: 5 }}>
            {new Date(pars.data.createdTimestamp*1000).toLocaleString()}
          </Text>
        </div>

        <div style={{ width: "40%", display: "grid", placeItems: "center" }}>
          <ProgressStateDisplay s={pars.data} />
        </div>
        <div style={{ width: "15%", display: "flex", justifyContent: "right" }}>
          <Button
            disabled={!canDownload}
            loading={loading}
            minimal
            icon={"download"}
            onClick={onButtonDownload}
            title="Download PDF"
          />
          <Button
            loading={loading}
            minimal
            icon={"trash"}
            onClick={onButtonDeleteJob}
          />
        </div>
      </div>
    </div>
  );
}

function ProgressStateDisplay(pars: { s: ScanJob }) {
  const [scanJob, setScanJob] = React.useState<ScanJob>(pars.s);
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    setScanJob(pars.s);
  }, [pars.s]);

  const onClickError=()=>{
    dispatchResponseError(scanJob.error!);
  };
  const state = scanJob.progressState;
  const scanJobId = scanJob._id;

  if (state === "limit_reached") {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginLeft: 5 }}>
          <Button
            style={{ borderRadius: 10 }}
            small
            loading={loading}
            icon={"play"}
            text="Start scan"
            onClick={() => {
              setLoading(true);
              new ScanOperationCmd()
                .run(scanJobId, "rescan")
                .then(setLoading.bind(null, false));
            }}
          />
        </div>
      </div>
    );
  }

  if (state === "error") {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ color: Colors.RED1 }}>
          <Button onClick={onClickError} icon="info-sign" minimal text="Error" style={{color:"red",fontStyle:"italic"}}></Button>
        </div>
        <div style={{ marginLeft: 5 }}>
          <Button
            style={{ borderRadius: 10 }}
            small
            loading={loading}
            icon={"refresh"}
            text="Scan again"
            onClick={() => {
              //new CancelScanJobCmd()
              setLoading(true);
              new ScanOperationCmd()
                .run(scanJobId, "rescan")
                .then(setLoading.bind(null, false));
            }}
          />
        </div>
      </div>
    );
  }
  //<ProgressBar value={1} intent="success" animate></ProgressBar>
  return (
    <div style={{ display: "flex", alignItems: "center", width: "80%" }}>
      <ProgressBar value={1} intent="none" animate></ProgressBar>
    </div>
  );
}
