import myFetch, { dispatchResponseError } from "./Helper";
import { InvoiceData } from "../shared/VOs";
import { Routes } from "../shared/Routes";

export default class AddLogMsgCmg {
  public async run(msg:string): Promise<void> {
    const route = Routes.addlogmsg;
    await myFetch(route, { msg },false);
  }
}
