import { Button, Dialog, DialogBody, DialogFooter } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { UserModel } from "models/UserModel";
import { DeleteErrorCmd } from "cmd/DeleteErrorCmd";
import { ErrorLog } from "shared";
import { ScanJobsModel } from "models/ScanJobsModel";

const maxRowsPerPage = 20;
const showEventName = "ErrorsPopup";
const changeEventName = UserModel.events.errorsChanged;

const onClickLeftButton = () => {
  new DeleteErrorCmd().run();
};
const createElementByData = (data: ErrorLog) => <ErrorRow data={data} />;

export function ErrorsPopup() {
  const [rows, setRows] = useState<{
    visible: boolean;
    elementList: Array<JSX.Element>;
    page: number;
  }>({ elementList: [], visible: false, page: 1 });

  useEffect(() => {
    const onShow = (_e: Event) => {
      const errorLogs = UserModel.instance.errorLogs;
      const elementList = errorLogs.map((data) => createElementByData(data));
      setRows({ visible: true, elementList, page: 1 });
    };
    document.addEventListener(showEventName, onShow);
    return () => {
      document.removeEventListener(showEventName, onShow);
    };
  }, []);

  useEffect(() => {
    const onDataChanged = (_e: Event) => {
      const errorLogs = UserModel.instance.errorLogs;
      const elementList = errorLogs.map((data) => createElementByData(data));
      setRows((prev) => {
        return { ...prev, elementList };
      });
    };
    if (rows.visible) {
      document.addEventListener(changeEventName, onDataChanged);
    }
    return () => {
      document.removeEventListener(changeEventName, onDataChanged);
    };
  }, [rows]);

  const onClose = () => {
    setRows((prev) => {
      return { ...prev, visible: false };
    });
  };

  const getMaxPages = (list: Array<JSX.Element>) => {
    const n = Math.floor(list.length / maxRowsPerPage);
    if (list.length / maxRowsPerPage > n) {
      return n + 1;
    }
    return n;
  };

  const onChangePage = (direction: "left" | "right") => {
    setRows((prev) => {
      const maxPages = getMaxPages(prev.elementList);
      let newPage = prev.page;
      if (direction === "left") {
        newPage = prev.page - 1;
      }
      if (direction === "right") {
        newPage = prev.page + 1;
      }
      if (newPage < 1) {
        newPage = maxPages;
      }
      if (newPage > maxPages) {
        newPage = 1;
      }
      return { ...prev, page: newPage };
    });
  };

  const getElementsToShow = () => {
    const start = (rows.page - 1) * maxRowsPerPage;
    const end = start + maxRowsPerPage;
    return rows.elementList.slice(start, end);
  };
  const elements = getElementsToShow();

  const renderElements = () => {
    return (
      <table>
        <tbody>
        <tr>
          <td style={{ width: "100px" }}>Time</td>
          <td style={{ width: "500px" }}>Error message</td>
          <td style={{ width: "100px" }}></td>
        </tr>
        {elements}
        </tbody>
      </table>
    );
  };

  return (
    <Dialog
      style={{ width: 700 }}
      isOpen={rows.visible}
      title="Errors"
      icon="info-sign"
      canOutsideClickClose
      onClose={onClose}
      onClosed={() => {
        setRows({ visible: false, elementList: [], page: 1 });
      }}
    >
      <DialogBody>
        {elements.length > 0 && (
          <div style={{ height: 400 }}>{renderElements()}</div>
        )}
        {elements.length === 0 && (
          <div style={{ height: 400 }}>No errors found</div>
        )}
      </DialogBody>

      <DialogFooter>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button intent="danger" icon="trash" onClick={onClickLeftButton}>
            Delete all
          </Button>
          <div style={{ display: "flex" }}>
            <Button
              icon="caret-left"
              onClick={onChangePage.bind(null, "left")}
            ></Button>
            <div style={{ margin: 5, width: 80, textAlign: "center" }}>
              Page {rows.page}/{getMaxPages(rows.elementList)}
            </div>
            <Button
              icon="caret-right"
              onClick={onChangePage.bind(null, "right")}
            ></Button>
          </div>
        </div>
      </DialogFooter>
    </Dialog>
  );
}

function ErrorRow(pars: { data: ErrorLog }) {

  const getScanJobError=(scanJobId:string):string|undefined=>{
    const scanJob=ScanJobsModel.instance.getScanJob(scanJobId);
    if(scanJob && scanJob.error) {
      return scanJob.error;
    }
  };
  return (
    <tr key={pars.data.timestamp}>
      <td>{new Date(pars.data.timestamp).toLocaleTimeString()}</td>
      <td>
        <div>{pars.data.msg}</div>
        {pars.data.scanJobId && <div><u>{getScanJobError(pars.data.scanJobId)}</u></div>}
      </td>
      <td>
        <Button
          icon="trash"
          onClick={() => {
            new DeleteErrorCmd().run(pars.data);
          }}
        ></Button>
      </td>
    </tr>
  );
}
