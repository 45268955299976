import myFetch, { dispatchResponseError, md5File, myFetchFile } from "./Helper";
import { PlanData, ScanJob } from "../shared/VOs"; // invoices-shared-lib/src/index.ts
import { Routes } from "../shared/Routes";
import { ScanJobsModel } from "../models/ScanJobsModel";
import { UserModel } from "../models/UserModel";
import { InvoicesModel } from "models/InvoicesModel";
import { maxPdfSize } from "../shared";
import AddLogMsgCmg from "./AddLogMsgCmd";

let didTrack=false;
export default class AddNewScanJobCmd {
  public async run(file: File): Promise<boolean> {

    const user = UserModel.instance.getUser();
    if (!user) {
      throw new Error("User not logged in");
    }

    try {
      if(!didTrack) {
        didTrack=true;
        (window as any).lintrk('track', { conversion_id: 16109409 });
      }
    } catch(e) {
      //
    }
  
    if(file.size>maxPdfSize) {
      new AddLogMsgCmg().run("clienterror_filetoolarge:"+file.name+", size "+file.size);
      const maxSizeMb=maxPdfSize/(1024*1024);
      UserModel.instance.addErrorLog("Can't add file "+file.name+". File size limit reached ("+maxSizeMb+"mb). ");
      return false;
    }
    if(file.type!=="application/pdf") {
      new AddLogMsgCmg().run("clienterror_fileisnotapdf:"+file.name);
      UserModel.instance.addErrorLog("Can't add file "+file.name+". File is not a valid pdf");
      return false;
    }
    const md5=await md5File(file);
    const alreadyExists=this.scanAlreadyExists(md5);
    if(alreadyExists) {
      new AddLogMsgCmg().run("clienterror_filealreadyexists:"+file.name);
      UserModel.instance.addErrorLog("Can't add file "+file.name+". File already present.");
      return false;
    }
    const curPlan=user.plan;
    if(curPlan.maxScans<=curPlan.scans) {
      new AddLogMsgCmg().run("clienterror_maxscansreached");
      UserModel.instance.addErrorLog("Can't add further scan files. Scan limit reached.");
      throw new Error("Max scans reached");
    }
    if(curPlan.maxStorage<=curPlan.storage) {
      UserModel.instance.addErrorLog("clienterror_maxstoragereached");
      throw new Error("Max storage reached");
    }
    const company=user.company;
    const userDefinedProps=[{key:"md5",value:md5},{key:"company",value:company?company:""} ];
    const route = Routes.addnewscanjob;
    const response = await myFetchFile(route, file, userDefinedProps);
    if (!response) {
      return false;
    }
    const json = await response.json();
    if (json.error) {
      dispatchResponseError(json.error);
      UserModel.instance.addErrorLog(json.error+" ("+file.name+")");
      return false;
    }
    if (json.data) {
      const scanJob = json.data.scanJob as ScanJob;
      ScanJobsModel.instance.addScanJobs([scanJob]);
      const updatedPlan = json.data.plan as PlanData;
      UserModel.instance.updatePlan(updatedPlan);
      return true;
    }
    return false;
  }

  private scanAlreadyExists(md5:string):boolean {

    const invoicesList=InvoicesModel.instance.invoices.list;
    for (let index = 0; index < invoicesList.length; index++) {
      const invoice = invoicesList[index];
      if(invoice.scanData.md5===md5) {
        return true;
      }
    }
    const scanJobsList=ScanJobsModel.instance.scans.list;
    for (let index = 0; index < scanJobsList.length; index++) {
      const scanJob = scanJobsList[index];
      if(scanJob.md5===md5) {
        return true;
      }
    }
    return false;
  }
}
