import { Routes } from "../shared";
import myFetch, { dispatchResponseError } from "./Helper";
import { UserModel } from "../models/UserModel";
import { ConfirmRequestCmd } from "./ConfirmRequestCmd";
import { ScanJobsModel } from "models/ScanJobsModel";
import { InvoicesModel } from "models/InvoicesModel";
import { QueryJobsModel } from "models/QueryJobsModel";

export class DeleteAccountCmd {
  public async run(): Promise<void> {
    const msg =
      "Are you sure you want to delete your accout? This action can't be undone.";
    const confirmDelete = await new ConfirmRequestCmd().run(msg);
    if (!confirmDelete) {
      return;
    }
    const route = Routes.deleteaccount;
    const response = await myFetch(route, {});
    if (!response) {
      return;
    }
    const json = await response.json();
    console.log("DeleteAccountCmd response", json);
    if (json.error) {
      dispatchResponseError(json.error);
    }
    if (json.data) {
      UserModel.instance.logout();
      ScanJobsModel.instance.setScanjobs([]);
      InvoicesModel.instance.setInvoices([]);
      QueryJobsModel.instance.setQueryJobs([]);
    }
  }
}
