import { AllScanJobs, ProgressState, ScanJob } from "../shared";

export class ScanJobsModel {
  public static readonly instance = new ScanJobsModel();

  public scans: AllScanJobs = { list: [], md5: "" };
  static events = { scanJobsChanged: "scanJobsChanged" };


  public hasScanJobsThatNeedUpdates(): boolean {
    return this.scans.list.some(
      (scanJob) => scanJob.progressState !== "done"
    );
  }
  
  public getOutstandingScanJobs(): Array<ScanJob> {
    const outstandingStates:Array<ProgressState>=["client_uploading","client_uploaded","server_db_uploading","server_db_uploaded","progressing"];
    return this.scans.list.filter(
      (scanJob) => outstandingStates.indexOf(scanJob.progressState)>=0
    );
  }

  public getFailedScanJobs(): Array<ScanJob> {
    return this.scans.list.filter(
      (scanJob) => scanJob.progressState==="error"
    );
  }

  public deleteScanJob(scanJobId: string) {
    const index = this.scans.list.findIndex(
      (sj) => sj._id === scanJobId
    );
    if (index >= 0) {
      this.scans.list.splice(index, 1);
      document.dispatchEvent(
        new CustomEvent(ScanJobsModel.events.scanJobsChanged, {
          detail: this.scans,
        })
      );
    }
  }

  public getScanJobs(): AllScanJobs {
    return this.scans;
  }

  public getScanJob(scanJobId: string):ScanJob|undefined {
    return this.scans.list.find((sj) => sj._id === scanJobId);
  }

  public setScanjobs(scanJobs: Array<ScanJob>)
  {
    this.scans.list=scanJobs;
    document.dispatchEvent(
      new CustomEvent(ScanJobsModel.events.scanJobsChanged, {
        detail: this.scans,
      })
    );
  }

  public addScanJobs(scanJobs: Array<ScanJob>) {
    scanJobs.forEach((scanJob) => {
      const index = this.scans.list.findIndex(
        (sj) => sj._id === scanJob._id
      );
      if (index >= 0) {
        this.scans.list.splice(index, 1, scanJob);
      } else {
        this.scans.list.push(scanJob);
      }
    });
    
    document.dispatchEvent(
      new CustomEvent(ScanJobsModel.events.scanJobsChanged, {
        detail: this.scans,
      })
    );
  }
}
