import React, { useState } from "react";
import { Suggest } from "@blueprintjs/select";
import { Button, MenuItem } from "@blueprintjs/core";
import QueryInvoicesCmd from "../../cmd/QueryInvoicesCmd";
import { UserModel } from "models/UserModel";

interface QueryType {
  title: string;
}

let index = 0;
export function QueryInput() {
  const items: Array<QueryType> = [
    { title: "Show the total sum of all invoices for Q1 2023." },
    {
      title:
        "Where did I travel? List all travel invoices and summarize the expenses.",
    },
    { title: "Show all invoices where the tax is under 10%." },
    { title: "List all the domain names i payed for." },
    { title: "Show me my office inventory." },
  ];
  let lastQueries = UserModel.instance.getUser()?.lastQueries;
  if (lastQueries) {
    //max 20
    lastQueries = lastQueries.slice(0, 20);
    //remove duplicates
    lastQueries = lastQueries.filter((v, i, a) => a.indexOf(v) === i).reverse();
    //put them on top
    items.unshift(...lastQueries.map((q) => ({ title: q })));
  }
  const [value, setValue] = useState<QueryType | undefined>();

  const [loading, setLoading] = React.useState(false);

  const onSendQuery = () => {
    if (!value || !value.title || value.title.trim().length === 0) {
      console.error("value is not set");
      return;
    }
    setLoading(true);
    new QueryInvoicesCmd()
      .run(value.title.trim())
      .then(setLoading.bind(null, false))
      .catch(console.error);
  };

  const handleItemRenderer = (
    item: {
      title:
        | string
        | number
        | boolean
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | React.ReactFragment
        | React.ReactPortal
        | null
        | undefined;
    },
    { modifiers, handleClick }: any
  ) => (
    <MenuItem
      active={modifiers.active}
      onClick={handleClick}
      text={item.title}
      key={index++}
    />
  );
  const handleInputValueRenderer = (inputValue: any) => {
    return inputValue.title;
  };

  const handleFilterItems = (
    query: string,
    item: { title: string },
    index: any,
    exactMatch: any
  ) => {
    const Title = item.title.toLowerCase();
    const Query = query.toLowerCase();

    if (exactMatch) {
      return Title === query;
    } else {
      return Title.indexOf(Query) >= 0;
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <Suggest
        disabled={loading}
        menuProps={{}}
        fill={true}
        inputValueRenderer={handleInputValueRenderer}
        items={items}
        itemRenderer={handleItemRenderer}
        itemPredicate={handleFilterItems}
        onItemsPaste={(items: QueryType[]) => {}}
        onItemSelect={(item: QueryType) => setValue(item)}
        selectedItem={value}
        // closeOnSelect={false}
        // openOnKeyDown={true}
        inputProps={{
          onValueChange: (v) => {
            setValue({ title: v });
          },

          maxLength: 500,

          style: { borderRadius: 5 },
          readOnly: loading,
          placeholder: "Enter prompt here",
          large: true,
          onKeyDown: (e) => {
            if (e.key === "Enter") {
              onSendQuery();
            }
          },
        }}
        popoverProps={{ minimal: true, disabled: loading }}
      ></Suggest>
      <div style={{ width: 5 }}></div>
      <Button large loading={loading} onClick={onSendQuery}>
        Send
      </Button>
    </div>
  );
}
