import { Button, Card, Colors, Divider, Icon } from "@blueprintjs/core";
import { QueryJobsModel } from "models/QueryJobsModel";
import { useState } from "react";
import { PlanList } from "shared";

export function About() {
  const [manualOpen, setManualOpen] = useState<boolean>(false);
  const hideFullView = QueryJobsModel.instance.allJobs.list.length > 0;

  const freePlan = PlanList.find((p) => p.planName === "free")!;
  const max = freePlan.scans + freePlan.initial;
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        {!hideFullView && (
          <div>
            <h3 style={{ color: Colors.GRAY1 }}>
            Prompt your invoices for their contents.
            </h3>
            <p>
              Invoice-Prompt uses AI to extract data from invoices, capturing
              details such as invoice number, date, price, and information about
              the listed products or services. This data can be easily accessed
              via an API, CSV export, or a straightforward prompt interface.
            </p>
          </div>
        )}
        {hideFullView && (
          <Button
            style={{ color: Colors.GRAY1, fontSize: 16, fontWeight: "bold" }}
            icon={manualOpen ? "caret-up" : "caret-down"}
            minimal
            onClick={() =>
              setManualOpen((p) => {
                return !p;
              })
            }
          >
            Prompt your invoices for their contents.
          </Button>
        )}
      </div>

      <div style={{ display: manualOpen || !hideFullView ? "block" : "none" }}>
        <Card>
          <h2>Main features</h2>
          <div style={{display:"block"}}>

            <BulletPoint msg="Securely and reliably store invoices. Share, hide or remove invoices with one click."/>
            <BulletPoint msg="Query invoice data with natural language. Summarize costs, dates, vendors, and more."/>
            <BulletPoint msg="Receive alerts for missing invoices, duplicates, and inconsistencies."/>
            <BulletPoint msg="Export data to CSV or through API."/>
          </div>
          <h2>FAQ</h2>
          <div>
            <h3>Who is this service designed for?</h3>
            Invoice-Prompt is designed for individuals and businesses that need
            to extract and process data from numerous invoices and then store
            both the data and the invoices. If you manage a business,
            Invoice-Prompt can help you monitor expenses, detail your spending,
            and analyze outlays. Additionally, it can identify missing invoices,
            duplicates, inconsistencies, and more. The only requirement is that
            the invoices are in PDF format.
            <h3>
              How does Invoice-Prompt differ from AskYourPDF, ChatGPT, and
              similar services?
            </h3>
            While platforms like AskYourPDF allow querying PDF contents, they
            lack the capability to compute based on the extracted data. Language
            Models, such as ChatGPT, aren't adept at calculations. Hence,
            relying on ChatGPT or AskYourPDF for tasks like summing up all
            invoices isn't feasible.
            <h3>How does Invoice-Prompt operate?</h3>
            Our service integrates two primary features: <br></br>
            <b>1. Scanning:</b> Invoices are transformed into a structured
            format using a blend of OCR and AI technologies. <br></br>
            <b>2. Prompting:</b> You can pose questions about the invoice in
            natural language. These prompts are then translated into structured
            queries, processed against the invoice data, and the results are
            relayed back. This mechanism mirrors the Advanced Data Analyzer
            functionality in ChatGPT.
            <br></br>
            <h3>What are some prompt examples?</h3>
            <div style={{ margin: 5 }}>
              <b>General prompts</b>
              <br></br>- How much did i pay in Q2?<br></br>- Show all invoices
              above 10 USD.<br></br>
              <Divider></Divider>
              <b>If you have invoices related to IT services</b>
              <br></br>- Show all .com domain names I purchased.<br></br>- How
              much did I pay for AWS in March 2023?<br></br>
              <Divider></Divider>
              <b>For regular business invoices</b>
              <br></br>- List invoice numbers from all accounting invoices.
              <br></br>- Extract all locations from travel invoices.<br></br>
            </div>
            <Divider></Divider>
            <i style={{ display: "none" }}>
              The service is free to use for up to {max} invoices. If you have
              more invoices from which you need to extract data, you can upgrade
              to a paid plan.
            </i>
          </div>
          <div
            style={{
              color: Colors.LIGHT_GRAY1,
              padding: 10,
              margin: 0,
              backgroundColor: Colors.DARK_GRAY3,
              borderRadius: 5,
            }}
          >
            For questions, feedback or support, please contact us at{" "}
            <a
              style={{ color: Colors.LIGHT_GRAY1 }}
              href="mailto:dev@invoice-prompt.com"
            >
              <i>dev@invoice-prompt.com</i>
            </a>
          </div>
        </Card>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            style={{ fontSize: 12, color: Colors.GRAY2 }}
            minimal
            small
            text="Imprint"
          />
          <Divider></Divider>
          <Button
            onClick={() => {
              document.dispatchEvent(new CustomEvent("TermsAndConditions"));
            }}
            style={{ fontSize: 12, color: Colors.GRAY2 }}
            minimal
            small
            text="Terms and conditions"
          />
          <Divider></Divider>
          <Button
            onClick={() => {
              document.dispatchEvent(new CustomEvent("TermsAndConditions"));
            }}
            style={{ fontSize: 12, color: Colors.GRAY2 }}
            minimal
            small
            text="Privacy policy"
          />
        </div>
      </div>
    </div>
  );
}


function BulletPoint(pars:{msg:string})
{
  return (
    <div style={{display:"flex"}}><Icon icon="tick"></Icon><Divider></Divider><div>{pars.msg}</div></div>
  );
}