import myFetch, { dispatchResponseError } from "./Helper";
import { AllInvoices } from "../shared/VOs";
import { Routes } from "../shared/Routes";
import { InvoicesModel } from "../models/InvoicesModel";

export default class GetInvoicesCmd {
  public async run(): Promise<void> {
    const route = Routes.getallinvoices;
    const response = await myFetch(route, {});
    if (!response) {
      return;
    }
    const json = await response.json();
    if (json.error) {
      dispatchResponseError(json.error);
    }
    if(json.data) {
      const invoices = json.data as AllInvoices;
      InvoicesModel.instance.setInvoices(invoices.list);
    }
  }
}
