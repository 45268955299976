import {
  Button,
  Colors,
} from "@blueprintjs/core";
import { useState } from "react";
import React from "react";

import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SetupCmd from "./cmd/SetupCmd";
import { UserModel } from "models/UserModel";
import { useCookies } from "react-cookie";
import { Dialogs } from "./pages/Dialogs";
import { StartPage } from "pages/StartPage";
import { InvoicesPopup } from "pages/popups/InvoicesPopup";
import { ScansPopup } from "pages/popups/ScansPopup";
import { UpgradePopup } from "pages/popups/UpgradePopup";
import { ErrorsPopup } from "pages/popups/ErrorsPopup";
import { TermsAndConditions } from "pages/TermsAndConditions";

/*interface LocationData {
  location: string;
  data?: object;
}*/
let lastErrorLogUpdate: Date | undefined;
let didCallSetup = false;

/*const createPageDiv = (page: JSX.Element) => {
  return (
    <div
      style={{
        display: "flex",
        marginLeft: "auto",
        marginRight: "auto",
        width: "700px",
        marginTop: 10,
      }}
    >
      <Divider></Divider>
      <div style={{ width: "700px" }}>{page}</div>
      <Divider></Divider>
    </div>
  );
};*/

export default function App() {
  const [cookies, setCookie, removeCookie] = useCookies(["loginFlag"]);
  let didLoginInPast = cookies.loginFlag === "true";
  if (!didCallSetup) {
    didCallSetup = true;
    if(didLoginInPast) {
      new SetupCmd().run("useExistingLoginCred");
    } else {
      new SetupCmd().run("noLoginData");
    }
  }

  /*const [location, setLocation] = useState<LocationData>({
    location: "/start",
  });*/

  const [setup, setSetup] = useState<boolean>(UserModel.instance.didSetup);

  useEffect(() => {
    const loginUpdateEvent = (e: Event) => {
      if (UserModel.instance.hasValidLogin()) {
        //setLocation({ location: "/query" });
        setCookie("loginFlag", "true", { path: "/" });
      } else {
        //setLocation({ location: "/login" });
        removeCookie("loginFlag", { path: "/" });
      }
    };

    const errorEvent = (e: Event) => {
      if (lastErrorLogUpdate) {
        const secPassed =
          (new Date().getTime() - lastErrorLogUpdate.getTime()) / 1000;
        if (secPassed < 5) {
          return;
        }
      }
      lastErrorLogUpdate = new Date();
      const ce = e as CustomEvent;
      const msg = ce.detail.toString();
      //console.log(msg);
      toast.error(msg,{position: toast.POSITION.TOP_CENTER});
    };
    const onSetupCompleted = (e: Event) => {
      setSetup(true);
    };
    const onGoto = (_e: Event) => {
      //const ce = e as CustomEvent;
      //setLocation({ location: ce.detail.location, data: ce.detail.data });
    };
    document.addEventListener("goto", onGoto);
    document.addEventListener("setup_completed", onSetupCompleted);
    document.addEventListener("login_update", loginUpdateEvent);
    document.addEventListener("server_network_error", errorEvent);
    document.addEventListener("server_response_error", errorEvent);
    return () => {
      document.removeEventListener("goto", onGoto);
      document.removeEventListener("setup_completed", onSetupCompleted);
      document.removeEventListener("login_update", loginUpdateEvent);
      document.removeEventListener("server_network_error", errorEvent);
      document.removeEventListener("server_response_error", errorEvent);
    };
  });

  if (!setup && didLoginInPast) {
    return (
      <div className={`App`}>
        <Loading />
        <ToastContainer />
      </div>
    );
  }

  /*const handleTabChange = (
    newTabId: TabId,
    prevTabId: TabId | undefined,
    event: React.MouseEvent<HTMLElement>
  ) => {
    setLocation({ location: newTabId.toString() });
  };*/

  //const hasLogin = UserModel.instance.hasValidLogin();

  return (
    <div className={`App`}>
      <Dialogs />
    
      <InvoicesPopup/>
      <ScansPopup/>
      <ErrorsPopup/>
      <UpgradePopup/>
      <TermsAndConditions/>
      <div
        className="AppContent"
        style={{ position: "absolute", top: 50, width: "100%" }}
      >
        <div
          style={{
            display: "flex",
            marginLeft: "auto",
            marginRight: "auto",
            minWidth: "350px",
            maxWidth: "740px",
          }}
        >
          <div style={{width:20}}></div>
          <StartPage key={setup?"1":"2"}></StartPage>
          <div style={{width:20}}></div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function InvoiceNavBar() {
  /*
       <div
        className="AppContent"
        style={{ position: "absolute", top: 50, width: "100%" }}
      >
        <BrowserRouter>
          <Routes location={location?.location}>
            <Route path="/start" element={createPageDiv(<StartPage />)} />
            <Route path="/query" element={createPageDiv(<QueryPage />)} />
            <Route path="/signup" element={createPageDiv(<SignUpPage />)} />
            <Route path="/login" element={createPageDiv(<LoginPage />)} />
            <Route path="/invoicelist" element={createPageDiv(<InvoiceListPage />)} />
            <Route path="/scanjobs" element={createPageDiv(<ScanJobsPage />)} />
            <Route path="/user" element={createPageDiv(<UserPage />)} />
          </Routes>
        </BrowserRouter>
      </div>
  */
  /*

        <Navbar fixedToTop>
        {hasLogin && (
          <NavbarGroup align={"left"}>
            <Tabs
              id="Tabs"
              onChange={handleTabChange}
              selectedTabId={location!.location}
            >
              <Tab id="/start" title="Start" />
              <Tab id="/scanjobs" title="Scans" />
              <Tab id="/invoicelist" title="Invoices" />
              <Tab id="/reports" title="Reports" />
              <Tab id="/query" title="Queries" />
              
            </Tabs>
          </NavbarGroup>
        )}

        {!hasLogin && (
          <NavbarGroup align={"left"}>
            <Tabs
              id="Tabs"
              onChange={handleTabChange}
              selectedTabId={location!.location}
            >
              <Tab id="/about" title="About" />
            </Tabs>
          </NavbarGroup>
        )}

        <NavbarGroup align={"right"}>
          {!hasLogin && (
            <Button
              icon="log-in"
              text="Login"
              onClick={() => {
                setLocation({ location: "/login" });
              }}
            />
          )}
          {!hasLogin && <NavbarDivider />}

          {!hasLogin && (
            <Button
              icon="log-out"
              text="Signup"
              onClick={() => {
                setLocation({ location: "/signup" });
              }}
            />
          )}
          {!hasLogin && <NavbarDivider />}

          {hasLogin && (
            <Button
              icon="user"
              onClick={() => {
                setLocation({ location: "/user" });
              }}
            />
          )}
        </NavbarGroup>
      </Navbar>
      */
}

function Loading() {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <div
        style={{ display: "block", textAlign: "center", color: Colors.GRAY4 }}
      >
        <Button loading minimal></Button>
        <p>Loading</p>
      </div>
    </div>
  );
}
