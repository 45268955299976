import myFetch, { dispatchResponseError } from "./Helper";
import { Routes } from "../shared/Routes";
import { AllScanJobs } from "../shared";
import { ScanJobsModel } from "../models/ScanJobsModel";

export default class GetScanJobsCmd {
  public async run(): Promise<AllScanJobs | undefined> {

    //console.log("GetScanJobsCmd");
    const route = Routes.getusercanjobs;
    const response = await myFetch(route, {});
    if (!response) {
      return;
    }
    const json = await response.json();
    if (json.error) {
      dispatchResponseError(json.error);
    }
    if (json.data) {
      //console.log("GetScanJobsCmd result", json.data);
      ScanJobsModel.instance.setScanjobs((json.data as AllScanJobs).list);
      return json.data as AllScanJobs;
    }
  }
}
