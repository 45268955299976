import {
  Button,
  Colors,
  Dialog,
  DialogBody,
  Divider,
  Icon,
  InputGroup,
} from "@blueprintjs/core";
import { SubmitUnavailablePlanCmd } from "cmd/SubmitUnavailablePlanCmd";
import { UserModel } from "models/UserModel";
import { useEffect, useState } from "react";
import { PlanList, PlanName } from "shared";

export function UpgradePopup() {
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<undefined | PlanName>(
    undefined
  );

  useEffect(() => {
    const onShow = (_e: Event) => {
      setVisible(true);
    };
    document.addEventListener("UpgradePopup", onShow);
    return () => {
      document.removeEventListener("UpgradePopup", onShow);
    };
  }, []);

  const onClose = () => {
    setSelectedPlan(undefined);
    setVisible(false);
  };

  const prettyNumber = (n?: number) => {
    if (!n || n < 1) {
      return "Unlimited";
    }
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const free = PlanList.find((p) => p.planName === "free")!;
  const individual = PlanList.find((p) => p.planName === "individual")!;
  const business = PlanList.find((p) => p.planName === "business")!;

  const renderPlans = () => {
    return (
      <table style={{ width: "100%", border: "0px solid black" }}>
        <tbody>
          <tr>
            <td style={{ width: 200 }}>Plan:</td>
            <td style={{ width: 80 }}>
              <b>Free</b>
            </td>
            <td style={{ width: 100 }}>
              <b>Individual</b>
            </td>
            <td style={{ width: 100 }}>
              <b>Business</b>
            </td>
          </tr>
          <tr>
            <td>Initial scans:</td>
            <td>{prettyNumber(free.initial + free.scans)}</td>
            <td>{prettyNumber(individual.initial+individual.scans)}</td>
            <td>{prettyNumber(business.initial+business.scans)}</td>
          </tr>

          <tr>
            <td>Additional scans per month:</td>
            <td>{prettyNumber(free.scans)}</td>
            <td>{prettyNumber(individual.scans)}</td>
            <td>{prettyNumber(business.scans)}</td>
          </tr>
          <tr>
            <td>Invoice storage limit:</td>
            <td>{prettyNumber(free.storage)}</td>
            <td>{prettyNumber(individual.storage)}</td>
            <td>{prettyNumber(business.storage)}</td>
          </tr>
          <tr>
            <td>Email reports:</td>
            <td>No</td>
            <td>Yes</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Scan & Data extraction via API:</td>
            <td>No</td>
            <td>No</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Lexoffice API:</td>
            <td>No</td>
            <td>No</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>Monthly price:</td>
            <td>${PlanList.find((p) => p.planName === "free")?.usd}</td>
            <td>${PlanList.find((p) => p.planName === "individual")?.usd}</td>
            <td>${PlanList.find((p) => p.planName === "business")?.usd}</td>
          </tr>

          <tr>
            <td></td>
            <td>
              <Button disabled>Active</Button>
            </td>
            <td>
              <Button onClick={setSelectedPlan.bind(null, "individual")}>
                Upgrade
              </Button>
            </td>
            <td>
              <Button onClick={setSelectedPlan.bind(null, "business")}>
                Upgrade
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <Dialog
      style={{ width: 600 }}
      isOpen={visible}
      title="Upgrade to a paid plan"
      icon="info-sign"
      canOutsideClickClose
      onClose={onClose}
      onClosed={() => {}}
    >
      <DialogBody>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {selectedPlan && (
            <PlanUnavailable planName={selectedPlan}></PlanUnavailable>
          )}
          {!selectedPlan && renderPlans()}
        </div>
      </DialogBody>
    </Dialog>
  );
}

function getPredefinedEmail(): string {
  const user = UserModel.instance.getUser();
  if (user) {
    if (user.guest) {
      return "";
    }
    return user.email;
  }
  return "";
}

function PlanUnavailable(pars: { planName: PlanName }) {
  const [loading, setLoading] = useState<"no" | "loading" | "done">("no");
  const [email, setEmail] = useState<string>(getPredefinedEmail());
  const onSubmit = () => {
    setLoading("loading");
    new SubmitUnavailablePlanCmd()
      .run({ email, planName: pars.planName })
      .then((result) => {
        setLoading(result ? "done" : "no");
      });
  };
  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  if (loading === "done") {
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        <div>
          <Icon icon="tick-circle" color={Colors.GREEN2} size={30}></Icon>
        </div>
        <div style={{ marginLeft: 10 }}>
          Thank you for your interest. <br></br>
          We will contact you as soon as we have capacity.
        </div>
      </div>
    );
  }
  return (
    <div style={{ display: "block" }}>
      <div style={{ display: "flex" }}>
        <div style={{ margin: 5 }}>
          <Icon size={30} icon="warning-sign" color={Colors.GRAY2}></Icon>
        </div>
        <div style={{ marginLeft: 5 }}>
          Due to high demand, we are currently not accepting new
          individual/business customers. If you are interested in using our
          invoice services, please leave your email below. We will contact you
          as soon as we have capacity.
        </div>
      </div>
      <div style={{ display: "flex", margin: 5, marginLeft: 45 }}>
        <InputGroup
          onKeyUp={(e) => {
            if (e.key === "Enter" && validateEmail(email)) {
              onSubmit();
            }
          }}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          disabled={loading !== "no"}
          type="text"
          placeholder="Type your email here"
        ></InputGroup>
        <div style={{ width: 5 }}></div>
        <Button
          intent="primary"
          disabled={validateEmail(email) ? false : true}
          loading={loading === "loading"}
          onClick={onSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}
