import { Button, Dialog, DialogBody, DialogFooter } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { ScanJobsModel } from "models/ScanJobsModel";
import { ScanRow } from "../common/ScanRow";
import DeleteScanJobCmd from "cmd/DeleteScanJobCmd";
import { ProgressState, ScanJob } from "shared";

const maxRowsPerPage = 20;
const showEventName = "ScansPopup";
const changeEventName = ScanJobsModel.events.scanJobsChanged;

const getDataList = () => {
  return ScanJobsModel.instance.getScanJobs().list;
};

const onClickLeftButton = () => {
  new DeleteScanJobCmd().run();
};
const createElementByData = (data: ScanJob) => <ScanRow data={data} />;

export function ScansPopup() {
  const [rows, setRows] = useState<{
    visible: boolean;
    elementList: Array<JSX.Element>;
    page: number;
  }>({ elementList: [], visible: false, page: 1 });

  useEffect(() => {
    const onShow = (_e: Event) => {
      const ce: CustomEvent = _e as CustomEvent;
      const elementList = getDataList().map((data) =>
        createElementByData(data)
      );
      setRows({ visible: true, elementList, page: 1 });
    };
    document.addEventListener(showEventName, onShow);
    return () => {
      document.removeEventListener(showEventName, onShow);
    };
  }, []);

  useEffect(() => {
    const onDataChanged = (_e: Event) => {
      const elementList = getDataList().map((data) =>
        createElementByData(data)
      );
      setRows((prev) => {
        return { ...prev, elementList };
      });
    };
    if (rows.visible) {
      document.addEventListener(changeEventName, onDataChanged);
    }
    return () => {
      document.removeEventListener(changeEventName, onDataChanged);
    };
  }, [rows]);

  const onClose = () => {
    setRows((prev) => {
      return { ...prev, visible: false };
    });
  };

  const getMaxPages = (list: Array<JSX.Element>) => {
    const n = Math.floor(list.length / maxRowsPerPage);
    if (list.length / maxRowsPerPage > n) {
      return n + 1;
    }
    return n;
  };

  const onChangePage = (direction: "left" | "right") => {
    setRows((prev) => {
      const maxPages = getMaxPages(prev.elementList);
      let newPage = prev.page;
      if (direction === "left") {
        newPage = prev.page - 1;
      }
      if (direction === "right") {
        newPage = prev.page + 1;
      }
      if (newPage < 1) {
        newPage = maxPages;
      }
      if (newPage > maxPages) {
        newPage = 1;
      }
      return { ...prev, page: newPage };
    });
  };

  const getElementsToShow = () => {
    const start = (rows.page - 1) * maxRowsPerPage;
    const end = start + maxRowsPerPage;
    return rows.elementList.slice(start, end);
  };
  const elements = getElementsToShow();

  return (
    <Dialog
      style={{ width: 700 }}
      isOpen={rows.visible}
      title="All current scans"
      icon="info-sign"
      canOutsideClickClose
      onClose={onClose}
      onClosed={() => {
        setRows({ visible: false, elementList: [], page: 1 });
      }}
    >
      <DialogBody>
        {elements.length > 0 && <div style={{ height: 400 }}>{elements}</div>}
        {elements.length === 0 && (
          <div style={{ height: 400 }}>No scans left</div>
        )}
      </DialogBody>

      <DialogFooter>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button intent="danger" icon="trash" onClick={onClickLeftButton}>
            Cancel all
          </Button>
          <div style={{ display: "flex" }}>
            <Button
              icon="caret-left"
              onClick={onChangePage.bind(null, "left")}
            ></Button>
            <div style={{ margin: 5, width: 80, textAlign: "center" }}>
              Page {rows.page}/{getMaxPages(rows.elementList)}
            </div>
            <Button
              icon="caret-right"
              onClick={onChangePage.bind(null, "right")}
            ></Button>
          </div>
        </div>
      </DialogFooter>
    </Dialog>
  );
}
