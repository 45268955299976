import { Button, Colors, Icon, Spinner } from "@blueprintjs/core";
import { InvoicesModel } from "models/InvoicesModel";
import { ScanJobsModel } from "models/ScanJobsModel";
import { UserModel } from "models/UserModel";
import { useEffect, useState } from "react";
import { ProgressState } from "shared";

export function UpperBar() {

  const [errorLogs, setErrorLogs] = useState<number>(
    UserModel.instance.errorLogs.length
  );
  const [failedScans, setFailedScans] = useState<number>(
    ScanJobsModel.instance.getFailedScanJobs().length
  );
  const [currentScans, setCurrentScans] = useState<number>(
    ScanJobsModel.instance.getOutstandingScanJobs().length
  );
  const [currentInvoices, setCurrentInvoices] = useState<number>(
    InvoicesModel.instance.getInvoices().list.length
  );
  useEffect(() => {
    const event1 = ScanJobsModel.events.scanJobsChanged;
    const onScanJobUpdated = (e: Event) => {
      setCurrentScans(ScanJobsModel.instance.getOutstandingScanJobs().length);
      setFailedScans(ScanJobsModel.instance.getFailedScanJobs().length);
    };

    const event2 = InvoicesModel.events.invoicesChanged;
    const onInvoicesUpdated = (e: Event) => {
      setCurrentInvoices(InvoicesModel.instance.getInvoices().list.length);
    };

    const event3 = UserModel.events.errorsChanged;
    const onErrorsCanaged = (e: Event) => {
      setErrorLogs(UserModel.instance.errorLogs.length);
    };

    document.addEventListener(event1, onScanJobUpdated);
    document.addEventListener(event2, onInvoicesUpdated);
    document.addEventListener(event3, onErrorsCanaged);

    return () => {
      document.removeEventListener(event1, onScanJobUpdated);
      document.removeEventListener(event2, onInvoicesUpdated);
      document.removeEventListener(event3, onErrorsCanaged);
    };
  }, []);
  const btnStyle = {
    color: Colors.LIGHT_GRAY1,
    marginRight: 5,
  };
  const hasContents = currentInvoices > 0 || currentScans > 0 || failedScans>0 || errorLogs>0;

  return (
    <div
      style={{
        display: hasContents ? "flex" : "none",
        alignItems: "center",
        fontSize: 12,
        padding: 5,
        borderRadius: 5,
        backgroundColor: Colors.DARK_GRAY2,
        border: "1px solid black",
        color: Colors.LIGHT_GRAY1,
      }}
    >
      {currentInvoices > 0 && (
        <Button
          onClick={() => {
            document.dispatchEvent(new Event("InvoicesPopup"));
          }}
          style={btnStyle}
          minimal
          small
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon icon="list" size={16} color={Colors.GREEN5} />
            <div style={{ marginLeft: 5 }}>Invoices: {currentInvoices}</div>
          </div>
        </Button>
      )}

      {(currentScans+failedScans) > 0 && (
        <Button
          onClick={() => {
            document.dispatchEvent(
              new CustomEvent("ScansPopup", { detail: { } })
            );
          }}
          style={btnStyle}
          minimal
          small
          title="Current scans in process"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {currentScans>0 && <Spinner size={16} color={Colors.WHITE} intent="warning" />}
            {currentScans>0 && <div style={{ marginLeft: 5 }}>Scanning: {currentScans} {currentScans>1?"invoices":"invoice"}</div>}
            {currentScans<1 && <Icon icon="warning-sign" size={16} color={Colors.RED4} />}
            {failedScans>0 && <div style={{ marginLeft: 5 }}>Failed: {failedScans} {failedScans>1?"scans":"scan"}</div>}
          </div>
        </Button>
      )}

      {errorLogs > 0 && (
        <Button
          onClick={() => {
            document.dispatchEvent(
              new CustomEvent("ErrorsPopup", {
                detail: {  },
              })
            );
          }}
          style={btnStyle}
          minimal
          small
          title=""
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon icon="warning-sign" size={16} color={Colors.RED4} />
            <div style={{ marginLeft: 5 }}>Warnings: {errorLogs}</div>
          </div>
        </Button>
      )}
    </div>
  );
}
