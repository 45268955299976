import { AllInvoices, InvoiceData, ParsedBasicInvoiceData } from "../shared";

export class InvoicesModel {
  public static readonly instance = new InvoicesModel();

  public readonly invoices: AllInvoices = { list: [], md5: "" };
  static events = { invoicesChanged: "invoicesChanged" };


  public getInvoices(): AllInvoices {
    return this.invoices;
  }
  public getInvoiceByNumber(invoiceNumber: string): InvoiceData | undefined {
    return this.invoices.list.find(
      (invoice) => invoice.parsedData?.invoiceNumber === invoiceNumber
    );
  }

  public getInvoiceById(invoiceId: string): InvoiceData | undefined {
    return this.invoices.list.find(
      (invoice) => invoice.invoiceId === invoiceId
    );
  }

  public updateInvoice(invoiceId: string,data:ParsedBasicInvoiceData) {
    const index = this.invoices.list.findIndex(
      (invoice) => invoice.invoiceId === invoiceId
    );
    if (index >= 0) {
      this.invoices.list[index].parsedData=data;
      document.dispatchEvent(
        new CustomEvent(InvoicesModel.events.invoicesChanged, {
          detail: this.invoices,
        })
      );
    }
  }

  public deleteInvoice(invoiceId: string) {
    const index = this.invoices.list.findIndex(
      (invoice) => invoice.invoiceId === invoiceId
    );
    if (index >= 0) {
      this.invoices.list.splice(index, 1);
      document.dispatchEvent(
        new CustomEvent(InvoicesModel.events.invoicesChanged, {
          detail: this.invoices,
        })
      );
    }
  }

  public setInvoices(invoices: Array<InvoiceData>)
  {
    this.invoices.list=invoices;
    document.dispatchEvent(
      new CustomEvent(InvoicesModel.events.invoicesChanged, {
        detail: this.invoices,
      })
    );
  }

  public addInvoices(invoices: Array<InvoiceData>) {
    console.log("InvoicesModel.addInvoices", invoices);
    invoices.forEach((invoice) => {
      const index = this.invoices.list.findIndex(
        (invoice2) => invoice2.invoiceId === invoice.invoiceId
      );
      if (index >= 0) {
        this.invoices.list[index] = invoice;
      } else {
        this.invoices.list.push(invoice);
      }
    });
    document.dispatchEvent(
      new CustomEvent(InvoicesModel.events.invoicesChanged, {
        detail: this.invoices,
      })
    );
  }
}
