import { InvoicesModel } from "../models/InvoicesModel";
import GetInvoicesCmd from "./GetInvoicesCmd";

export default class DownloadAllInvoicesCmd {
  public async run(): Promise<void> {
    await new GetInvoicesCmd().run();

    const rows = [
      ["Gross", "Net", "Currency", "Date", "Issuer", "Invoice Number", "Customer"]
    ];

    InvoicesModel.instance.invoices.list.forEach((invoice) => {
      const pd=invoice.parsedData!
      rows.push([
        pd.fullPrice.gross+"",
        pd.fullPrice.net+"",
        pd.fullPrice.currencyCode,
        new Date(pd.dateIssued.timestamp*1000).toLocaleDateString(),
        pd.issuer.name,
        pd.invoiceNumber,
        pd.customer.name
      ]);
    });

    let csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "all_invoices.csv");
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".
  }
}
