export class ConfirmRequestCmd {
  public async run(msg: string): Promise<boolean> {
    return new Promise<boolean>((r) => {
      const ce = new CustomEvent("confirm_request", {
        detail: { callback: r, msg },
      });
      document.dispatchEvent(ce);
    });
  }
}
