import { UserModel } from "models/UserModel";
import { Routes } from "../shared/Routes";
import CryptoJS from "crypto-js";

export default async function myFetch(
  route: string,
  json: any,
  dispatchError: boolean = true
): Promise<Response | null> {
  var headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");

  let server:string = Routes.server_dev;
  if(UserModel.instance.env==="production") {
    server = Routes.server_prod;
  }

  return new Promise<Response | null>((e) => {
    UserModel.instance.fetching = true;
    fetch(server + route, {
      method: "POST",
      cache: "no-cache",
      credentials: "include", // Don't forget to specify this if you need cookies
      headers: headers,
      body: JSON.stringify(json),
    })
      .then((response: Response) => {
        if (response.status === 200) {
          UserModel.instance.prolongLoginExpiresAt();
          e(response);
          return;
        }
        if (response.status === 401) {
          //token invalid. reponse is json containing error
          UserModel.instance.logout();
          e(response);
          return;
        }
        e(null);
        dispatchResponseError("Status code " + response.status);
      })
      .catch((error: any) => {
        //console.log("myFetch catch ",error);
        if (dispatchError) {
          dispatchNetworkError();
        }
        e(null);
      })
      .finally(() => {
        UserModel.instance.fetching = false;
      });
  });
}

export function dispatchNetworkError() {
  //console.log("dispatchNetworkError");
  document.dispatchEvent(
    new CustomEvent("server_network_error", {
      detail: "Server network error",
    })
  );
}

export function dispatchResponseError(msg: string) {
  //console.log("dispatchResponseError", msg);
  document.dispatchEvent(
    new CustomEvent("server_response_error", {
      detail: msg,
    })
  );
}

export async function md5File(file: File): Promise<string> {
  const md5 = await new Promise<string>((e) => {
    var reader = new FileReader();
    reader.onload = (event) => {
      const resultString = event.target?.result?.toString();
      var md5 = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(resultString!));
      e(md5.toString());
    };
    reader.readAsBinaryString(file);
  });
  return md5;
}

export async function myFetchFile(
  route: string,
  file: File,
  data?:Array<{key:string,value:string}>
): Promise<Response | null> {
  var formData = new FormData();
  formData.append("file", file);

  //console.log("myFetchFile",data);
  if(data) {
    data.forEach((e) => {
      formData.append(e.key, e.value);
    });
  }

  let server:string = Routes.server_dev;
  if(UserModel.instance.env==="production") {
    server = Routes.server_prod;
  }
  const dispatchError = false;

  return new Promise<Response | null>((e) => {
    UserModel.instance.fetching = true;
    fetch(server + route, {
      method: "POST",
      cache: "no-cache",
      credentials: "include", // Don't forget to specify this if you need cookies
      body: formData,
    })
      .then((response: Response) => {
        if (response.status === 200) {
          UserModel.instance.prolongLoginExpiresAt();
          e(response);
          return;
        }
        if (response.status === 401) {
          UserModel.instance.logout();
          e(null);
          return;
        }
        e(null);
        dispatchResponseError("Status code " + response.status);
      })
      .catch((error: any) => {
        //console.log("myFetch catch ",error);
        if (dispatchError) {
          dispatchNetworkError();
        }
        e(null);
      })
      .finally(() => {
        UserModel.instance.fetching = false;
      });
  });
}

export function alertReponse(er: any): void {
  console.log("alert response", er);
  if (er.errors.length > 0) {
    document.dispatchEvent(
      new CustomEvent("client_warn", {
        detail: "Failed entries: " + er.errors.length,
      })
    );
  }
  if (er.validEntries > 0) {
    document.dispatchEvent(
      new CustomEvent("client_success", {
        detail: "Success entries: " + er.validEntries,
      })
    );
  }
}
