import { UpdateUserData, Routes } from "../shared";
import myFetch, { dispatchResponseError } from "./Helper";
import { UserModel } from "models/UserModel";

export class UpdateUserDataCmd {
  public async run(pars: UpdateUserData): Promise<void> {
    const route = Routes.updateuserdata;

    const response = await myFetch(route, pars);
    if (!response) {
      return;
    }
    const json = await response.json();
    if (json.error) {
      dispatchResponseError(json.error);
    }
    if (json.data) {
      UserModel.instance.setLogin(json.data.user);
    }
  }
}
