import { Colors, Divider } from "@blueprintjs/core";
import { InvoicesModel } from "models/InvoicesModel";
import { ClassifiedRow } from "pages/common/ClassifiedRow";
import { InvoiceRow } from "pages/common/InvoiceRow";
import { InvoiceItemDataAny } from "shared";

const commonStyle= {
    margin:5,
    padding:0,
    display:"block",
    //border:"2px solid black",
    //backgroundColor:Colors.LIGHT_GRAY5,
};
let index=0;

function translateKeyIntoName(camelCaseKey:string):string
{
    let words=camelCaseKey.split(/(?=[A-Z])/);
    //make first letter of the first word uppercase. all other words should be lower case
    words=words.map((w,i)=>{
        if(i===0) {
            return w.charAt(0).toUpperCase()+w.slice(1);
        }
        return w.toLowerCase();
    });
    return words.join(" ");
}

function createWrappedDiv(inner:string|number|boolean|JSX.Element|Array<JSX.Element>):JSX.Element
{
    return <div key={index++} style={commonStyle}>{inner}</div>;
}
function createPrimitiveDiv(inner:string|number|boolean|JSX.Element|Array<JSX.Element>):JSX.Element
{
    return <div key={index++}>{inner}</div>;
}
function createNoEntriesDiv():JSX.Element
{
    return <div key={index++} style={{fontStyle:"italic",color:Colors.GRAY2}}>No entries</div>;
}

export function QueryObjectWrapper(obj:any):JSX.Element
{
    if(typeof obj === "string")
    {
        return createPrimitiveDiv(obj);
    }
    if(typeof obj === "number")
    {
        //round to 4 decimal places
        const num = Math.round(obj*10000)/10000;
        return createPrimitiveDiv(num);
    }
    if(typeof obj === "boolean")
    {
        return createPrimitiveDiv(obj?"YES":"NO");
    }
    if(obj === null || obj === undefined) {
        return createNoEntriesDiv();
    }
    if(obj instanceof Array) {
        const arr = obj as Array<any>;
        const list2 = new Array<JSX.Element>();
        for(let j=0;j<arr.length;j++) {
            const element=QueryObjectWrapper(arr[j]);
            if(element) {
                list2.push(element);
            }
        }
        if(list2.length>0) {
            return createWrappedDiv(list2);
        }
        return createNoEntriesDiv();
    }
    if(obj instanceof Object) {
        const list2 = new Array<JSX.Element>();
        const keys=Object.keys(obj);

        const isInvoiceJSON=keys.indexOf("invoiceNumber")>=0 && keys.indexOf("fullPrice")>=0 && keys.indexOf("customer")>=0;
        if(isInvoiceJSON) {
            //is trying to log the json of the invoice. prevent that.
            const v = obj["invoiceNumber"];
            const invoiceData = InvoicesModel.instance.getInvoiceByNumber(v);
            if(invoiceData) {
                list2.push(((<InvoiceRow key={index++} data={invoiceData} />)));
            }
        } else {
            //is trying to log something else
            for(let i=0;i<keys.length;i++) {
                const k=keys[i];//k is property name, e.g. invoiceNumber
                const v = obj[k];
                if(k==="invoiceNumber") {
                    const invoiceData = InvoicesModel.instance.getInvoiceByNumber(v);
                    if(invoiceData) {
                        list2.push(((<InvoiceRow key={index++} data={invoiceData} />)));
                    }
                    continue;
                } 
                if (k==="type") {
                    list2.push((<ClassifiedRow key={index++} obj={v as InvoiceItemDataAny} />));
                    continue;
                }
                const nam = translateKeyIntoName(k);
                const valElement = QueryObjectWrapper(v);
                list2.push(createWrappedDiv(<div style={{display:"flex"}}><div>{nam}</div>:&nbsp;{valElement}</div>));
            }
        }
 
        if(list2.length>0) {
            return <div key={index++}>{list2}</div>
        }
        return createNoEntriesDiv();
    }
    return createNoEntriesDiv();
}