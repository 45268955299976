/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { ProgressState } from "../../shared/VOs";
import SignUpCmd from "../../cmd/SignUpCmd";
import { Button, Checkbox, Icon, InputGroup } from "@blueprintjs/core";
import {
  ClrState,
  ColoredDivider,
  checkStates,
} from "../common/ColoredDivider";

export function SignUpPage() {
  const [isEnabled, setIsEnabled] = React.useState(false);
  const [companyName, setCompanyName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState<"no" | "creating" | "done">(
    "no"
  );

  const handleEnabledChange = () => {
    setIsEnabled(!isEnabled);
  };

  const onSignUpButton = () => {
    setLoading("creating");
    new SignUpCmd().run({ company: companyName, email, password }).then(() => {
      setLoading("done");
    });
  };

  const states = checkStates({ companyName, email, password });
  const clrStateEmail: ClrState = states.clrStateEmail;
  const clrStatePassword: ClrState = states.clrStatePassword;
  const clrStateCompanyName: ClrState = states.clrStateCompanyName;
  const allSuccess = isEnabled && states.allSuccess;

  const onTerms=()=>{
    document.dispatchEvent(new CustomEvent("TermsAndConditions"));
  };

  return (
    <div style={{ display: "block" }}>
      <b>Company name</b>
      <InputGroup
        type="text"
        placeholder="Type here"
        value={companyName}
        onChange={(v) => setCompanyName(v.target.value)}
      />
      <ColoredDivider state={clrStateCompanyName}></ColoredDivider>
      <b>Email</b>
      <InputGroup
        type="text"
        placeholder="Type here"
        value={email}
        onChange={(v) => setEmail(v.target.value)}
      />
      <ColoredDivider state={clrStateEmail}></ColoredDivider>
      <b>Password</b>
      <InputGroup
        type="password"
        placeholder="********"
        value={password}
        onChange={(v) => setPassword(v.target.value)}
      />
      <ColoredDivider state={clrStatePassword}></ColoredDivider>

      <div style={{ display: "flex" }}>
        <Checkbox checked={isEnabled} onChange={handleEnabledChange}></Checkbox>
        <div>
          i agree with{" "}
          <u>
            <a href="#" onClick={onTerms}>terms and conditions</a>
          </u>
        </div>
      </div>
      <Button
        fill
        onClick={onSignUpButton}
        loading={loading === "creating"}
        disabled={!allSuccess}
        intent="primary"
      >
        Sign up
      </Button>
    </div>
  );
}
