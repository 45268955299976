import myFetch, { dispatchResponseError } from "./Helper";
import { Routes } from "../shared/Routes";
import SetupCmd from "./SetupCmd";
import { UserModel } from "models/UserModel";
import { UpdateUserDataCmd } from "./UpdateUserDataCmd";

export default class SignUpCmd {
  public async run(pars: {
    company: string;
    email: string;
    password: string;
  }): Promise<void> {


    const hasGuestUser = ()=> {
      const user=UserModel.instance.getUser();
      if(user) {
        return user.guest;
      }
      return false;
    };

    if(hasGuestUser()) {
      UserModel.instance.loginData=pars;
      await new UpdateUserDataCmd().run(pars);
      return;
    }

    const route = Routes.signup;
    const response = await myFetch(route, pars);
    if (!response) {
      return;
    }
    UserModel.instance.loginData=pars;
    const json = await response.json();
    if (json.error) {
      dispatchResponseError(json.error);
    }
    if (json.data) {
      //
      await new SetupCmd().run("signup");
    }
  }
}
