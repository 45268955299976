
import { AllQueryJobs, QueryJob } from "../shared";

export class QueryJobsModel {

    public static readonly instance=new QueryJobsModel();

    public readonly allJobs: AllQueryJobs={list:[],md5:""};
    static events = {queryJobsChanged:"queryJobsChanged"};

    public deleteAll()
    {
        this.allJobs.list=[];
        document.dispatchEvent(new CustomEvent(QueryJobsModel.events.queryJobsChanged, {detail: this.allJobs}));
    }

    public hasOutstandingQueryJobs()
    {
        return this.allJobs.list.find((j)=>j.queryState==="pending")?true:false;
    }

    public getQueryJobs(): AllQueryJobs {
        return this.allJobs;
    }

    public removeQueryJob(jobID:string)
    {
        const index = this.allJobs.list.findIndex((j) => j._id === jobID);
        if (index !== -1) {
            this.allJobs.list.splice(index,1);
            document.dispatchEvent(new CustomEvent(QueryJobsModel.events.queryJobsChanged, {detail: this.allJobs}));
        }
    }


    public setQueryJobs(jobs: Array<QueryJob>)
    {
        this.allJobs.list=jobs;
        document.dispatchEvent(new CustomEvent(QueryJobsModel.events.queryJobsChanged, {detail: this.allJobs}));
    }


    public addQueryJobs(jobs: Array<QueryJob>) {
        
        jobs.forEach((job) => {
            const index = this.allJobs.list.findIndex((j) => j._id === job._id);
            if (index === -1) {
                this.allJobs.list.push(job);
            } else {
                this.allJobs.list[index] = job;
            }
        });
        document.dispatchEvent(new CustomEvent(QueryJobsModel.events.queryJobsChanged, {detail: this.allJobs}));
    }

    
}