import myFetch, { dispatchResponseError } from "./Helper";
import { Routes } from "../shared/Routes";
import { UserModel } from "models/UserModel";
import SetupCmd from "./SetupCmd";

export default class LoginCmd {
  public async run(pars: {
    email: string;
    password: string;
  }): Promise<boolean> {
    const route = Routes.login;
    console.log("LoginCmd.run", pars);
    const response = await myFetch(route, pars);
    if (!response) {
      return false;
    }
    UserModel.instance.loginData=pars;

    const json = await response.json();
    if (json.error) {
      dispatchResponseError(json.error);
    }
    if (json.data) {
      await new SetupCmd().run("login");
      return true;
    }
    UserModel.instance.logout();
    return false;

  }
}
