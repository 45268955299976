import myFetch, { dispatchResponseError } from "./Helper";
import { Routes } from "../shared/Routes";
import { QueryJob } from "../shared";
import { QueryJobsModel } from "../models/QueryJobsModel";
import { UserModel } from "models/UserModel";
import { InvoicesModel } from "models/InvoicesModel";
import AddLogMsgCmg from "./AddLogMsgCmd";

export default class QueryInvoicesCmd {
  public async run(query: string): Promise<void> {
    const invoices = InvoicesModel.instance.invoices;
    if (invoices.list.length === 0) {
      dispatchResponseError("You have no invoices to query.");
      return;
    }

    const route = Routes.queryinvoices;
    UserModel.instance.addLastQuery(query);
    const response = await myFetch(route, { query });
    if (!response) {
      return;
    }
    const json = await response.json();
    if (json.error) {
      dispatchResponseError(json.error);
    }

    if (json.data) {
      const queryJob = json.data as QueryJob;
      QueryJobsModel.instance.addQueryJobs([queryJob]);
    }
  }
}
