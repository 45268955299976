import { AllQueryJobs, AllScanJobs, Routes, UserData } from "../shared";
import myFetch, { dispatchResponseError } from "./Helper";
import { QueryJobsModel } from "../models/QueryJobsModel";
import { InvoicesModel } from "../models/InvoicesModel";
import { ScanJobsModel } from "../models/ScanJobsModel";
import { UserModel } from "models/UserModel";
import MinuteIntervalCmd from "./MinuteIntervalCmd";

export default class SetupCmd {
  public async run(source:"noLoginData"|"useExistingLoginCred"|"login"|"signup"): Promise<void> {

    const currentUrl = window.location.href;
    const debug = currentUrl.indexOf("localhost")!==-1;
    UserModel.instance.env=debug?"development":"production";

    if(source==="noLoginData") {
      //no login needed
    } else {
      await this.runWithExistingLogin();
    }

    UserModel.instance.didSetup = true;
    document.dispatchEvent(new CustomEvent("setup_completed"));
    new MinuteIntervalCmd().run(); 
  }

  private async runWithExistingLogin():Promise<boolean>
  {
    const route = Routes.getuser;
    const response = await myFetch(route, {});
    if (!response) {
      return false;
    }
    if(response.status===401) {
      //
      // Message:: Authentication token missing
      UserModel.instance.logout();
      return true;
    }
    const json = await response.json();
    if (json.error) {
      dispatchResponseError(json.error);
      return false;
    }
    if (json.data) {
      const user: UserData = json.data.user;
      const queryJobs: AllQueryJobs = json.data.queryJobs;
      const scanJobs: AllScanJobs = json.data.scanJobs;
      QueryJobsModel.instance.addQueryJobs(queryJobs.list);
      InvoicesModel.instance.addInvoices(user.invoices.list);
      ScanJobsModel.instance.addScanJobs(scanJobs.list);
      UserModel.instance.setLogin(user);
    }
    return true;
  }
}
