import React, { useEffect } from "react";
import {
  InvoiceItemDataAddress,
  InvoiceItemDataAny,
  InvoiceItemDataDomain,
} from "../../shared/VOs";
import { Icon } from "@blueprintjs/core";

export function ClassifiedRow(pars: { obj: InvoiceItemDataAny }) {
  const [data, setData] = React.useState<InvoiceItemDataAny>(pars.obj);

  useEffect(() => {
    setData(pars.obj);
  }, [pars.obj]);

  const type = data?.type;
  if (type === "address") {
    const address = data as InvoiceItemDataAddress;
    return (
      <div style={{display:"flex"}}>
        <Icon icon="map-marker" />
        {address.value && <div>Street: {address.value}</div>}
      </div>
    );
  }
  if (type === "domain_name") {
    const domain = data as InvoiceItemDataDomain;
    return (
      <div>
        <a href={data.value} target="_top">Domain:{domain.value}</a>
      </div>
    );
  }
  return <div>{JSON.stringify(data)}</div>;
}
