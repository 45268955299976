import React, { useEffect } from "react";
import { InvoiceData } from "../../shared/VOs";
import { InvoicesModel } from "../../models/InvoicesModel";
import { Button, Card, Colors, Divider, Icon, Text } from "@blueprintjs/core";
import InvoiceOperationCmd from "cmd/InvoiceOperationCmd";
import { EditInvoice } from "pages/popups/EditInvoice";

export function InvoiceRow(pars: { data: InvoiceData }) {
  const [data, setData] = React.useState<InvoiceData>(pars.data);
  const [open, setOpen] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | undefined>(undefined);

  const [loadingDownload, setLoadingDownload] = React.useState<boolean>(false);

  useEffect(() => {
    const data = InvoicesModel.instance.getInvoiceById(pars.data.invoiceId);
    if (data) {
      setData(data);
    } else {
      setError("Invoice not found for invoiceId: " + pars.data.invoiceId);
    }
  }, [pars.data]);

  if (error) {
    return <div>{error}</div>;
  }

  const onButtonDownload = () => {
    setLoadingDownload(true);
    new InvoiceOperationCmd()
      .run(data.invoiceId, "download")
      .then(() => {
        //
      })
      .finally(() => {
        setLoadingDownload(false);
      });
  };

  let issuer = "-";
  let gross = "-";
  let date = "";

  if(data.parsedData) {
    date = new Date(
      data.parsedData.dateIssued.timestamp * 1000
    ).toLocaleDateString();
    issuer = data.parsedData.issuer.name;
    if (issuer.length > 50) {
      issuer = issuer.substr(0, 50) + "...";
    }
    let lang=data.parsedData.languageCode;
    if(lang==="en") lang="en-US";
    if(lang==="de") lang="de-DE";
    else if(!lang) lang="en-US";
    const numberForm= new Intl.NumberFormat(lang, { style: 'currency', currency: data.parsedData.fullPrice.currencyCode });
    gross = numberForm.format(data.parsedData.fullPrice.gross);
  }


  const renderOpenContent = () => {
    let periodStart: string | undefined = undefined;
    let periodEnd: string | undefined = undefined;
    const parsedData = data.parsedData!;
    const completedTimestamp = new Date(data.scanData?.completed);

    if (parsedData.datePeriod) {
      periodStart = new Date(
        parsedData.datePeriod.startTimestamp * 1000
      ).toLocaleDateString();
      periodEnd = new Date(
        parsedData.datePeriod.endTimestamp * 1000
      ).toLocaleDateString();
    }

    //console.log("renderOpenContent", parsedData);

    return <EditInvoice invoiceData={data}></EditInvoice>;
    /**
     *      <div style={{ margin: 5, display: "block" }}>
          <div>Items/services:</div>
          <div style={{margin:5}}>{renderDetails()}</div>
        </div>
     * 
     */

    /*return (
      <div style={{ marginLeft: 5, marginRight: 5 }}>
        <div style={{ margin: 5 }}>
          Customer: {parsedData.customer.name}
        </div>
        <div style={{ margin: 5 }}>
          Invoice number: {parsedData.invoiceNumber}
        </div>

        {periodStart && periodEnd && (periodStart!==periodEnd) && <div style={{ margin: 5 }}>
          Period: {periodStart} - {periodEnd}
        </div>}
        <div style={{ margin: 5 }}>
          Tax: {parsedData.taxPercent + "%"}
        </div>

        <div style={{ margin: 5, display: "flex" }}>
          <div style={{ alignSelf: "center" }}>Tags: </div>
          <Tags key={123} tags={parsedData.tags} />
        </div>

        <div style={{ margin: 5, display: "flex", color:Colors.GRAY2 }}>
          <div>Scan date: {new Date(completedTimestamp).toLocaleString()}</div>
          <Divider></Divider>
          <div>File: {data.scanData.fileName}</div>
          <Divider></Divider>
          {data.parsedData.plausibility && <div>Plausibility score: {data.parsedData.plausibility.score}</div>}
        </div>

        <Button loading={loadingRescan} onClick={onButtonRescan} icon="refresh" text="Scan again" style={{ margin: 5 }} />
        <Button loading={loadingDelete} onClick={onButtonDelete} icon="trash" text="Delete invoice" intent="danger" style={{ margin: 5 }} />
        
      </div>
    );*/
  };

  const getTickTitle=()=>{
    const d=new Date(pars.data.parsedData.datePaid.timestamp*1000);
    return "This symbol indicates that the invoice was paid on: "+d.toLocaleDateString();
  };

  return (
    <Card
      key={pars.data.invoiceId}
      onClick={() => {
        if (!open) setOpen(true);
      }}
      interactive={!open}
      style={{ borderRadius: 10, margin: 0, padding: 5, marginBottom: 5 }}
    >
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <div style={{ width: "20%",display:"flex" }}>
          <Text style={{ marginRight: 0, marginLeft: 10 }}>{gross}</Text>
          {pars.data.parsedData.datePaid.timestamp>0 && <Icon title={getTickTitle()} icon="tick" style={{ marginLeft: 5, color:Colors.GREEN3}} />}
        </div>
        <div style={{ width: "20%" }}>
          <Text style={{ marginRight: 10, marginLeft: 10 }}>{date}</Text>
        </div>

        <div style={{ width: "60%" }}>
          <Text
            style={{ marginRight: 10, marginLeft: 10, textAlign: "center" }}
          >
            {issuer}
          </Text>
        </div>
        <div style={{ width: "20%", display: "flex", justifyContent: "right" }}>
          <Button
            loading={loadingDownload}
            minimal
            icon={"download"}
            onClick={onButtonDownload}
            title="Download PDF"
          />
          <Button
            minimal
            icon={open ? "caret-up" : "caret-down"}
            style={{}}
            onClick={() => setOpen(!open)}
          />
        </div>
      </div>
      {open && renderOpenContent()}
    </Card>
  );
}
