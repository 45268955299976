import myFetch, { dispatchResponseError } from "./Helper";
import * as INV from "../shared/Routes";
import { ScanJobsModel } from "models/ScanJobsModel";

export default class DeleteScanJobCmd {
  public async run(scanJobId?: string): Promise<void> {
    const route = INV.Routes.deletescanjob;

    if (typeof scanJobId === "undefined") {
      ScanJobsModel.instance.setScanjobs([]);
    } else {
      ScanJobsModel.instance.deleteScanJob(scanJobId);
    }

    const response = await myFetch(route, { scanJobId });
    if (!response) {
      return;
    }
    const json = await response.json();
    if (json.error) {
      dispatchResponseError(json.error);
    }
  }
}
