import React from "react";

import LoginCmd from "../../cmd/LoginCmd";
import { Button, InputGroup } from "@blueprintjs/core";
import { ClrState, ColoredDivider } from "../common/ColoredDivider";
import { UserModel } from "models/UserModel";

export function LoginPage() {

  let presetEmail = "";
  let presetPassword = "";

  if (UserModel.instance.loginData) {
    presetEmail = UserModel.instance.loginData.email;
    presetPassword = UserModel.instance.loginData.password;
  }

  const [email, setEmail] = React.useState(presetEmail);
  const [password, setPassword] = React.useState(presetPassword);
  const [loading, setLoading] = React.useState(false);

  const onLoginButton = () => {
    setLoading(true);
    new LoginCmd()
      .run({ email, password })
      .finally(() => {
        setLoading(false);
      });
  };

  const onForgotButton = () => {};

  let clrStateEmail: ClrState = "primary";
  let clrStatePassword: ClrState = "primary";

  if (email.length > 0) {
    if (email.indexOf("@") > 0) {
      clrStateEmail = "success";
    } else {
      clrStateEmail = "danger";
    }
  }

  if (password.length > 0) {
    if (password.length > 5) {
      clrStatePassword = "success";
    } else {
      clrStatePassword = "danger";
    }
  }

  const allSuccess =
    clrStateEmail === "success" && clrStatePassword === "success";

  return (
    <div style={{ display: "block" }}>
      <b>Email</b>
      <InputGroup
        type="text"
        placeholder="Type here"
        value={email}
        onChange={(v) => setEmail(v.target.value)}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            onLoginButton();
          }
        }}
      />
      <ColoredDivider state={clrStateEmail}></ColoredDivider>
      <b>Password</b>
      <InputGroup
        type="password"
        placeholder="********"
        value={password}
        onChange={(v) => setPassword(v.target.value)}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            onLoginButton();
          }
        }}
      />
      <ColoredDivider state={clrStatePassword}></ColoredDivider>

      <div style={{ display: "flex" }}>
        <Button
          onClick={onLoginButton}
          loading={loading}
          disabled={!allSuccess}
          intent="primary"
        >
          Login
        </Button>
        <div style={{ width: 5 }}></div>

        <Button fill onClick={onForgotButton} disabled={loading}>
          I forgot my password
        </Button>
      </div>
    </div>
  );
}
