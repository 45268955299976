import { useState } from "react";
import { FileDrop } from "react-file-drop";
import AddNewScanJobCmd from "../cmd/AddNewScanJobCmd";
import { Button, Colors, Icon, Text } from "@blueprintjs/core";
import { UserModel } from "../models/UserModel";
import { PlanData, PlanList } from "shared";
import { CreateGuestUserIfRequiredCmd } from "cmd/CreateGuestUserIfRequiredCmd";

export default function DropInvoice() {
  const [loading, setIsLoading] = useState<boolean>(false);

  const onDropFiles = async (files: FileList | null) => {
    if (files && files.length > 0) {
      setIsLoading(true);
      await new CreateGuestUserIfRequiredCmd().run();
      try {
        for (let i = 0; i < files.length; i++) {
          await new AddNewScanJobCmd().run(files[i]);
        }
      } catch (e: any) {
        document.dispatchEvent(
          new CustomEvent("server_response_error", {
            detail: e.message,
          })
        );
      }
      setIsLoading(false);
    }
  };

  const onSelectFilesButton = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "application/pdf";
    input.multiple = true;
    input.onchange = (e) => {
      if (e.target) {
        const files = (e.target as HTMLInputElement).files;
        if (files) {
          onDropFiles(files);
        }
      }
    };
    input.click();
  };

  const w = "100%";
  const h = 180;

  const renderFrameDrop = () => {
    return (
      <FileDrop
        onFrameDragEnter={(_event) => {}}
        onFrameDragLeave={(_event) => {}}
        onFrameDrop={(_event) => {}}
        onDragOver={(_event) => {}}
        onDragLeave={(_event) => {}}
        onDrop={(files, _event) => {
          _event.preventDefault();
          onDropFiles(files);
        }}
      >
        <div
          style={{ width: w, height: h, display: "grid", placeItems: "center" }}
        >
          <div style={{ display: "block", textAlign: "center" }}>
            <Icon
              size={40}
              icon="document"
              style={{ color: Colors.GRAY3 }}
            ></Icon>
            <div style={{ height: 10, pointerEvents: "none" }}></div>
            <Text style={{ color: Colors.GRAY1, fontWeight: "bold" }}>
              Drag and drop PDF invoices here
            </Text>
            <Text style={{ color: Colors.GRAY1 }}>or</Text>
            <div style={{ height: 10, pointerEvents: "none" }}></div>
            <Button text="Select files" onClick={onSelectFilesButton}></Button>
          </div>
        </div>
      </FileDrop>
    );
  };

  const renderUploading = () => {
    return (
      <div
        style={{ width: w, height: h, display: "grid", placeItems: "center" }}
      >
        <div style={{ display: "block", textAlign: "center" }}>
          <Button minimal loading={true}></Button>
          <Text style={{ color: Colors.GRAY1 }}>Uploading...</Text>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        alignContent: "center",
        borderRadius: 5,
        width: w,
        height: h,
        border: "2px dashed",
        backgroundColor: Colors.LIGHT_GRAY4,
        position: "relative",
      }}
    >
      <div style={{ position: "absolute", right: 0 }}>
        <CurrentPlanSticker />
      </div>

      {loading && renderUploading()}
      {!loading && renderFrameDrop()}
    </div>
  );
}

function CurrentPlanSticker() {
  if (!UserModel.instance.didSetup) {
    return <div></div>;
  }
  const onClickUpgrade = () => {
    document.dispatchEvent(new CustomEvent("UpgradePopup", { detail: {} }));
  };
  let plan: PlanData | undefined = undefined;

  const user = UserModel.instance.getUser();
  if (user) {
    plan = user.plan;
  } else {
    const predefinedPlan = PlanList.find((p) => p.planName === "free")!;
    plan = {
      maxScans: predefinedPlan.scans + predefinedPlan.initial,
      maxStorage: predefinedPlan.storage,
      name: predefinedPlan.planName,
      scans: 0,
      storage: 0,
      startTimestamp: 0,
    };
  }

  const planName = plan.name.charAt(0).toUpperCase() + plan.name.slice(1);
  return (
    <div
      style={{
        backgroundColor: Colors.WHITE,
        borderRadius: 5,
        color: Colors.GRAY1,
        fontSize: 12,
        width: 92,
        margin: 5,
        padding: 5, 
      }}
    >
      {planName} plan
      <br></br>
      {plan.scans + "/" + plan.maxScans} Scans
      {plan.scans >= plan.maxScans && (
        <div style={{ color: Colors.RED1, pointerEvents: "none" }}>
          Limit reached
        </div>
      )}
      <div style={{ marginTop: 5 }}>
        <Button style={{ fontSize: 12 }} small onClick={onClickUpgrade}>
          Upgrade
        </Button>
      </div>
    </div>
  );
}
