import myFetch, { dispatchResponseError } from "./Helper";
import { Routes } from "../shared/Routes";
import { ScanJobsModel } from "../models/ScanJobsModel";
import { InvoicesModel } from "../models/InvoicesModel";
import { ScanJob, InvoiceOperation, PlanData, ParsedBasicInvoiceData } from "../shared";
import { UserModel } from "../models/UserModel";
import { CheckPlanLimitCmd } from "./CheckPlanLimitCmd";
import { ConfirmRequestCmd } from "./ConfirmRequestCmd";

export default class InvoiceOperationCmd {
  public async run(
    invoiceId: string,
    operation: InvoiceOperation,
    data?:ParsedBasicInvoiceData
  ): Promise<void> {

    //console.log("InvoiceOperationCmd",invoiceId,operation,data);

    if(operation==="rescan") {
      if(!await (new CheckPlanLimitCmd().run())) {
        return;
      }
    }
    if(operation==="delete") {
      const msg="Are you sure you want to delete this invoice? This action can't be undone.";
      const result=await (new ConfirmRequestCmd().run(msg));
      if(!result) {
        return;
      }
    }  

    const route = Routes.invoiceoperation;
    const response = await myFetch(route, { invoiceId, operation, data });
    if (!response) {
      return;
    }
    if(operation==="download") {
      const invoice=InvoicesModel.instance.getInvoiceById(invoiceId)!;
      const pdfBlob=await response.blob();
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Create a link and trigger the download
        const downloadLink = document.createElement('a');
        downloadLink.href = pdfUrl;
        downloadLink.download = invoice.scanData.fileName;
        downloadLink.click();
        return;
    }

    const json = await response.json();
    if (json.error) {
      dispatchResponseError(json.error);
    }
    if (json.data) {
      if (operation === "rescan") {
        const scanJob=json.data.scanJob as ScanJob;
        ScanJobsModel.instance.addScanJobs([scanJob]);
        InvoicesModel.instance.deleteInvoice(invoiceId);
        const updatedPlan = json.data.plan as PlanData;
        UserModel.instance.updatePlan(updatedPlan);
        document.dispatchEvent(new CustomEvent("goto", { detail: {data:undefined,location:"/scanjobs"} }));
      }
      if (operation === "delete") {
        InvoicesModel.instance.deleteInvoice(invoiceId);
      }
      if (operation === "update") {
        InvoicesModel.instance.updateInvoice(invoiceId,data!);
      }
    }
  }
}
