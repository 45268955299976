import { Routes } from "shared";
import myFetch, { dispatchResponseError } from "./Helper";
import { UserModel } from "models/UserModel";

export class CreateGuestUserIfRequiredCmd {
  public async run(): Promise<boolean> 
  {
    const hasUser=UserModel.instance.getUser()?true:false;
    if(hasUser) {
      return false;
    }
    const route = Routes.signupguest;
    const response = await myFetch(route, {});
    if (!response) {
      return false;
    }
    const json = await response.json();
    if (json.error) {
      dispatchResponseError(json.error);
      return false;
    }
    if (json.data) {
      const user = json.data.user;
      UserModel.instance.setLogin(user);
      return true;
    }
    return false;
  }
}
