import { Button, Classes, Divider, Popover } from "@blueprintjs/core";
import { UserModel } from "models/UserModel";
import { LoginPage } from "pages/account/LoginPage";
import { SignUpPage } from "pages/account/SignUpPage";
import { UserPage } from "pages/account/UserPage";
import { useEffect, useState } from "react";


type BtnState = "login" | "signup" | "account";

function getCurrentState():BtnState
{
  if (UserModel.instance.isGuestUser()) {
    return "signup";
  }
  if (UserModel.instance.getUser()) {
    return "account";
  }
  return "login";
}

export function AccountButton() {
  
  const [page, setPage] = useState<BtnState>(getCurrentState());

  useEffect(() => {
    const onChange=(_e:Event)=>{
      setPage(getCurrentState());
    };
    document.addEventListener(UserModel.events.loginUpdate, onChange);
    return () => {
      document.removeEventListener(UserModel.events.loginUpdate, onChange);
    };
  }, []);

  const renderBody = () => {
    if (page === "login") {
      return (
        <div style={{ padding: 10, width: 300 }}>
          <LoginPage />
          <Divider></Divider>
          <Button
            small
            minimal
            icon="plus"
            fill
            onClick={setPage.bind(null, "signup")}
          >
            Register new account
          </Button>
        </div>
      );
    }
    if (page === "signup") {
      return (
        <div style={{ padding: 10, width: 300 }}>
          <SignUpPage />
          <Divider></Divider>
          <Button
            small
            minimal
            icon="log-in"
            fill
            onClick={setPage.bind(null, "login")}
          >
            Already have an account?
          </Button>
        </div>
      );
    }

    if (page === "account") {
      return (
        <div style={{ padding: 10, width: 300 }}>
          <UserPage user={UserModel.instance.getUser()!} />
        </div>
      );
    }
  };
  return (
    <Popover
      interactionKind="click"
      popoverClassName={Classes.POPOVER_CONTENT_PLACEMENT}
      placement="bottom"
      content={renderBody()}
      onClosed={() => {setPage(getCurrentState())}}
      renderTarget={({ isOpen, ...targetProps }) => (
        <Button
          {...targetProps}
          minimal
          style={{
            marginRight: 0,
            paddingRight: 0,
          }}
          rightIcon="user"
        >
          {page === "login" ? "Login" : ""}
          {page === "account" ? "Account" : ""}
          {page === "signup" ? "Register" : ""}
        </Button>
      )}
    />
  );
}
