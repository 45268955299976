import { UserModel } from "models/UserModel";

export class CheckPlanLimitCmd {
  public async run(): Promise<boolean> {
    const plan = UserModel.instance.getUser()!.plan;
    if((plan.storage>=plan.maxStorage && plan.maxStorage>0) || (plan.scans>=plan.maxScans && plan.maxScans>0)) {
        const msg="You can not start more scans since your reached your plans limit. Please upgrade your plan.";
        document.dispatchEvent(new CustomEvent("confirm_request", {detail:{msg,callback:(r:boolean)=>{}}}));
        return false;
    }
    return true;
  }
}
