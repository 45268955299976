import React, { useEffect, useState } from "react";
import { PlanData, PlanName, UserData } from "../../shared";
import { UserModel } from "../../models/UserModel";
import { Button, Divider, Icon, InputGroup } from "@blueprintjs/core";
import LogoutCmd from "../../cmd/LogoutCmd";
import {
  ClrState,
  ColoredDivider,
  checkStates,
} from "../common/ColoredDivider";
import { UpdateUserDataCmd } from "../../cmd/UpdateUserDataCmd";
import { DeleteAccountCmd } from "cmd/DeleteAccountCmd";

export function UserPage(pars: { user: UserData }) {
  const presetPassword = "**********";
  const [updateUserData, setUpdateUserData] = React.useState<UserData>({
    ...pars.user,
    passwordHash: presetPassword,
  });

  const [loadingDelete, setLoadingDelete] = useState(false);

  const [loadingUpdate, setLoadingUpdate] = React.useState<
    "no" | "loading" | "success" | "error"
  >("no");

  useEffect(() => {
    const onUpdateUser = (e: Event) => {
      const cd = e as CustomEvent;
      setUpdateUserData({
        ...(cd.detail as UserData),
        passwordHash: presetPassword,
      });
    };
    document.addEventListener(UserModel.events.userDataUpdate, onUpdateUser);
    return () => {
      document.removeEventListener(
        UserModel.events.userDataUpdate,
        onUpdateUser
      );
    };
  }, []);

  const onChangeEmail = (newEmail: string) => {
    setUpdateUserData((prev) => {
      return { ...prev, email: newEmail };
    });
  };
  const onChangeCompany = (newCompany: string) => {
    setUpdateUserData((prev) => {
      return { ...prev, company: newCompany };
    });
  };
  const onChangePassword = (newPassword: string) => {
    setUpdateUserData((prev) => {
      return { ...prev, passwordHash: newPassword };
    });
  };

  const onClickLogout = () => {
    new LogoutCmd().run();
  };

  const onClickDelete = () => {
    setLoadingDelete(true);
    new DeleteAccountCmd().run().then(() => {
      setLoadingDelete(false);
    });
  };

  const onClickUpgrade = () => {
    document.dispatchEvent(new CustomEvent("UpgradePopup", { detail: {} }));
  };

  const onClickUpdateButton = () => {
    setLoadingUpdate("loading");
    if (updateUserData.passwordHash === presetPassword) {
      updateUserData.passwordHash = undefined;
    }
    new UpdateUserDataCmd()
      .run({
        email: updateUserData.email,
        company: updateUserData.company,
        password: updateUserData.passwordHash,
      })
      .then(() => {
        setLoadingUpdate("success");
      })
      .catch(() => {
        setLoadingUpdate("error");
      });
  };

  if (!updateUserData) {
    return <div>Loading...</div>;
  }

  const states = checkStates({
    companyName: updateUserData.company,
    email: updateUserData.email,
    password: updateUserData.passwordHash
      ? updateUserData.passwordHash
      : presetPassword,
  });
  let clrStateEmail: ClrState = states.clrStateEmail;
  let clrStatePassword: ClrState = states.clrStatePassword;
  let clrStateCompanyName: ClrState = states.clrStateCompanyName;
  const allSuccess = states.allSuccess;

  return (
    <div style={{ display: "block", width: "100%" }}>
      <b>User</b>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td>Company</td>
            <td>
              <InputGroup
                disabled={loadingUpdate === "loading"}
                type="text"
                value={updateUserData.company}
                onChange={(e) => {
                  onChangeCompany(e.target.value);
                }}
              ></InputGroup>
              <ColoredDivider state={clrStateCompanyName}></ColoredDivider>
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>
              <InputGroup
                disabled={loadingUpdate === "loading"}
                type="text"
                value={updateUserData.email}
                onChange={(e) => {
                  onChangeEmail(e.target.value);
                }}
              ></InputGroup>
              <ColoredDivider state={clrStateEmail}></ColoredDivider>
            </td>
          </tr>
          <tr>
            <td>Password</td>
            <td>
              <InputGroup
                disabled={loadingUpdate === "loading"}
                type="password"
                value={updateUserData.passwordHash}
                onChange={(e) => {
                  onChangePassword(e.target.value);
                }}
              ></InputGroup>
              <ColoredDivider state={clrStatePassword}></ColoredDivider>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <div style={{ display: "flex", alignItems: "center" }}>
                {loadingUpdate === "error" && (
                  <div style={{ marginRight: 10 }}>
                    <Icon icon="error" color="red"></Icon> ERROR{" "}
                  </div>
                )}
                {loadingUpdate === "success" && (
                  <div style={{ marginRight: 10 }}>
                    <Icon icon="confirm" color="green"></Icon> OK{" "}
                  </div>
                )}
                <Button
                  onClick={onClickUpdateButton}
                  text="Update"
                  disabled={!allSuccess}
                  loading={loadingUpdate === "loading"}
                ></Button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <Divider />

      <b>Plan</b>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td>Current plan:</td>
            <td>
              <div style={{ display: "flex" }}>
                <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                  {updateUserData.plan?.name.toUpperCase()}{" "}
                </div>
                <Button small minimal onClick={onClickUpgrade}>
                  (Upgrade)
                </Button>
              </div>
            </td>
          </tr>
          <tr>
            <td>Start:</td>
            <td>
              {new Date(
                updateUserData.plan?.startTimestamp * 1000
              ).toLocaleDateString()}
            </td>
          </tr>
          <tr>
            <td>Interval:</td>
            <td>Monthly</td>
          </tr>

          <tr>
            <td>Storage:</td>
            <td>
              {updateUserData.plan?.storage +
                "/" +
                updateUserData.plan?.maxStorage}
            </td>
          </tr>
        </tbody>
      </table>

      <Divider />

      <b>Account</b>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button fill text="Logout" onClick={onClickLogout}></Button>
        <div style={{ width: 10 }}></div>
        <Button
          loading={loadingDelete}
          fill
          text="Delete account"
          intent="danger"
          onClick={onClickDelete}
        ></Button>
      </div>
    </div>
  );
}

/*function PlanButtons(pars: { plan: PlanData }) {
  const [loading, setLoading] = React.useState(false);

  const onUpgrade = (planName: PlanName) => {
    setLoading(true);
    new UpgradePlanCmd().run({ planName }).then(() => {
      setLoading(false);
    });
  };

  const curPlanName = pars.plan?.name!;
  const allPlans = PlanList;
  const curPlanIndex = allPlans.findIndex((p) => p.planName === curPlanName);

  const renderButton = (name: PlanName, max: string) => {
    const nameFirstLetterUpperCase =
      name.charAt(0).toUpperCase() + name.slice(1);
    return (
      <div
        style={{
          textAlign: "center",
          display: "block",
          width: 100,
          marginRight: 10,
        }}
      >
        <Button
          loading={loading}
          onClick={onUpgrade.bind(null, name)}
          fill
          text={nameFirstLetterUpperCase}
        ></Button>
        <div style={{ fontSize: 10 }}>{max}</div>
      </div>
    );
  };

  const listButtons = new Array<JSX.Element>();
  for (let i = curPlanIndex + 1; i < allPlans.length; i++) {
    const pd = allPlans[i];
    const max = pd.max;
    listButtons.push(renderButton(pd.planName, max + " Scans"));
  }
  return <div style={{ display: "flex" }}>{listButtons}</div>;
}
*/
