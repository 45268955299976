import { Colors } from "@blueprintjs/core";

export type ClrState = "success" | "danger" | "warning" | "primary";



export function checkStates(pars:{companyName:string,email:string,password:string}):{allSuccess:boolean,clrStateEmail:ClrState,clrStatePassword:ClrState,clrStateCompanyName:ClrState}
{
  const companyName = pars.companyName;
  const email = pars.email;
  const password = pars.password;

  let clrStateEmail: ClrState = "primary";
  let clrStatePassword: ClrState = "primary";
  let clrStateCompanyName: ClrState = "primary";

  if(companyName && companyName.length>0) {
    if(companyName.length>2) {
        clrStateCompanyName = "success";
    } else {
        clrStateCompanyName = "danger";
    }
  }

  if(email && email.length>0) {
    if(email.indexOf("@")>0) {
        clrStateEmail = "success";
    } else {
        clrStateEmail = "danger";
    }
  }

  if(password && password.length>0) {
    if(password.length>5) {
        clrStatePassword = "success";
    } else {
        clrStatePassword = "danger";
    }
  }

  const allSuccess=clrStateEmail==="success" && clrStatePassword==="success" && clrStateCompanyName==="success";
  return {allSuccess,clrStateEmail,clrStatePassword,clrStateCompanyName};
}


export function ColoredDivider(pars: { state: ClrState }) {
  const state = pars.state;
  if (state === "success") {
    return <div style={{ backgroundColor: "white", height: 6 }}></div>;
  }
  if (state === "danger") {
    return <div style={{ backgroundColor: "red", height: 6 }}></div>;
  }
  if (state === "warning") {
    return <div style={{ backgroundColor: "yellow", height: 6 }}></div>;
  }
  if (state === "primary") {
    return (
      <div
        style={{
          marginTop: 2,
          marginBottom: 2,
          backgroundColor: Colors.LIGHT_GRAY1,
          height: 2,
        }}
      ></div>
    );
  }
  return <div></div>;
}
