import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
} from "@blueprintjs/core";
import { useEffect, useState } from "react";

export function Dialogs() {
  const [data, setData] = useState<
    { msg: string; callback: (yes: boolean) => void } | undefined
  >();

  useEffect(() => {
    const onConfirmRequest = (e: Event) => {
      const ce = e as CustomEvent;
      const msg = ce.detail.msg;
      const callback = ce.detail.callback;
      setData({ msg, callback });
    };
    document.addEventListener("confirm_request", onConfirmRequest);
    return () => {
      document.removeEventListener("confirm_request", onConfirmRequest);
    };
  }, []);

  const onClickConfirm = (yes: boolean) => {
    setData((prevData) => {
      prevData?.callback(yes);
      return undefined;
    });
  };

  return (
    <Dialog
      canOutsideClickClose
      onClose={onClickConfirm.bind(null, false)}
      isOpen={data ? true : false}
      title="Please confirm"
      icon="info-sign"
    >
      <DialogBody>
        <div>{data?.msg}</div>
      </DialogBody>
      <DialogFooter
        actions={
          <div style={{ display: "flex" }}>
            <Button
              intent="primary"
              text="Confirm"
              onClick={onClickConfirm.bind(null, true)}
            />
          </div>
        }
      />
    </Dialog>
  );
}
