import ScanJobsPart from "./ScanJobsPart";
import QueryPart from "./QueryPart";
import { UpperBar } from "./UpperBar";
import { UserModel } from "models/UserModel";
import { AccountButton } from "./account/AccountButton";
import { About } from "./About";

export function StartPage() {
  //console.log("StartPage render: did setup " + UserModel.instance.didSetup);
  return (
    <div style={{ display: "block" }}>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <AccountButton />
      </div>
      <div style={{ height: 5 }}></div>
      <ScanJobsPart></ScanJobsPart>
      <div style={{ height: 5 }}></div>
      <UpperBar></UpperBar>
      <div style={{ height: 5 }}></div>
      <QueryPart></QueryPart>
      <div style={{ height: 5 }}></div>
      <About></About>
      <div style={{ height: 100 }}></div>
    </div>
  );
}


