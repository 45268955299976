import { type } from "os";

export interface AllInvoices {
  md5: string;
  list: Array<InvoiceData>;
}
export interface AllScanJobs {
  md5: string;
  list: Array<ScanJob>;
}
export interface AllQueryJobs {
  md5: string;
  list: Array<QueryJob>;
}

export interface ErrorLog {
  msg: string;
  timestamp: number;
  scanJobId?: string;
}

export interface InvoiceData {
  invoiceId: string;
  parsedData: ParsedBasicInvoiceData;
  scanData: {
    shareId:string;
    userId: string;
    scanJobId: string;
    pdfId: string;
    completed: number; //when the scan was completed
    fileName: string; //file name transmitted from the client
    md5:string;//set by user when initial scan
  };
}

export const maxPdfSize=2 * 1024 * 1024;
//export const shareLink="https://srv.clickbot.pro:8100/share/:shareId";
export const shareLink="https://invoice-prompt.com/share/?invoice=:shareId";

export const addressFormat = "City, Street, Postal Code, State, Country";

export type ScanOperation = "download" | "rescan" | "delete";
export type InvoiceOperation = "download" | "rescan" | "delete" | "update";

export type PlanName = "test" | "free" | "individual" | "business";
export interface PlanData {
  name: PlanName;
  startTimestamp: number;

  scans: number;
  storage: number;

  maxScans: number;
  maxStorage: number;
}
export const PlanList: Array<{
  planName: PlanName;
  initial: number;
  scans: number;
  storage: number;
  usd: number;
}> = [
  {
    planName: "test",
    scans: 2,
    initial: 10,
    storage: 2,
    usd: 0,
  },
  {
    planName: "free",
    scans: 30,
    initial: 70,
    storage: 600,
    usd: 0,
  },
  {
    planName: "individual",
    scans: 250,
    initial: 250,
    storage: 6000,
    usd: 29,
  },
  {
    planName: "business",
    scans: 2500,
    initial: 2500,
    storage: 0,
    usd: 179,
  },
];
export interface UserData {
  _id?: any;
  email: string;
  company: string;
  passwordHash?: string;
  invoices: AllInvoices;
  register: { date: Date; ip: string };
  lastLogin: { date: Date; ip: string };
  plan: PlanData;
  appliedPlan?: PlanName; //this is for now as long as the individual/business plan is not implemented
  settings?: {
    forwardToEmail: string;
    alertInvalidEmail: boolean;
  };
  lastQueries: Array<string>;
  guest?: boolean;
}

export interface UpdateUserData {
  email: string;
  company: string;
  password?: string;
}

export interface QueryJob {
  _id?: any;
  workerId: number;
  createdTimestamp: number;
  userId: string;
  queryState: QueryState;
  originalMsg: string;
  adjustedMsg: string;
  //
  error?: string;
  queryBeginTimestamp?: number;
  queryDoneTimestamp?: number;
  usage?: UsageDataList;
  js?: string;
  result?: Array<string>;
}

export interface ScanJob {
  _id?: any;
  workerId: number;
  createdTimestamp: number;
  userId: string;
  pdfId: string;
  progressState: ProgressState;
  shareId: string;

  //user defined data sent with every scan.
  fileName: string;
  md5: string;
  userCompany:string;//helps with invoice scanning to determine who is the issuer and who is the customer
  //
  error?: string;
  scanBeginTimestamp?: number;
  scanDoneTimestamp?: number;
  usage?: UsageDataList;
}

export type ProgressState =
  | "client_uploading"
  | "client_uploaded"
  | "server_db_uploading"
  | "server_db_uploaded"
  | "progressing"
  | "done"
  | "error"
  | "cancelled"
  | "limit_reached";

export type QueryState =
  | "pending"
  | "processing"
  | "running"
  | "done"
  | "error";

export class FallbackData {
  currency: string = "EUR";
  //languageCode: string = "en";
  customerName: string = "XXXX";
  customerAddress: string = "XXXX";
}

export interface IUser {
  id: string;
  name: string;
  email: string;
  password: string;
  terms: boolean;
  remember: boolean;
  ip: string;
}

export type UsageDataList = Array<UsageData>;

export interface UsageData {
  model: string;
  prompt_tokens: number;
  completion_tokens: number;
  total_tokens: number;
  usd: number;
  totalRequests: 0,
  totalErrors: 0,
}

export interface InvoiceHeadData {
  progress: ProgressState;
  fileName: string;
  invoiceId: string;
}

export interface InvoiceDataDetails {
  contents: string;
  anyElements?: Array<string>;
  quantity: number;
  price: TaxedPriceData;
  tip: TaxedPriceData;
  tags?: Array<string>;
  classifiedData?: InvoiceItemData;
}

export type CustomerSalutation = "MR." | "MS." | "COMPANY" | "";
export interface IssuerData {
  name: string;
  address: string;
  taxNumber: string;
}
export interface CustomerData {
  accountNumber: string;
  name: string;
  address: string;
}

export interface BasicInvoiceData {
  issuer: IssuerData;
  customer: CustomerData;
  invoiceNumber: string;
  fullPrice: TaxedPriceData;
  taxPercent: number;
  languageCode: string;
}

export interface ParsedBasicInvoiceData extends BasicInvoiceData {
  //no extractors needed
  plausibility?: PlausibilityData;
  detailsPriceSumWillMatchFullPrice?: boolean;
  optionalCurrencyExchangeData?: CurrencyExchangeData;
  tags: Array<string>;
  datePaid:DateData;
  public?:boolean;
  //
  //overview extractor
  //paymentBank: { iban: string; bic: string };
  //paymentMethod: string;

  //details extractor
  details: Array<InvoiceDataDetails>;

  //dates extractor
  dateIssued: DateData;
  datePeriod: DateRangeData;
}

export type PartialInvoiceData = Partial<ParsedBasicInvoiceData>;

export const TagExamples =
  "Membership Fee, Domains, Rent, Phone, Office Supplies, Utilities, Internet, Travel, Taxi, Uber, Plane, Train, Advertising, Insurance, Maintenance, Equipment, Subscription, Marketing, Legal, Training, Development, Software, Inventory"
    .split(",")
    .map((s) => s.trim());

export interface CurrencyExchangeData {
  fromCurrencyCode: string;
  toCurrencyCode: string;
  rate: number;
}

export interface PlausibilityData {
  score: number;
  waysToImprove: Array<string>;
}

export interface PriceData {
  value: number;
  currencyCode: string;
  original?: string;
}

export interface TaxedPriceData {
  net: number;
  gross: number;
  currencyCode: string;
}

export interface DateData {
  timestamp: number;
  original?: string;
}

export interface PercentData {
  percent: number;
  original?: string;
}

export interface DateRangeData {
  startTimestamp: number;
  endTimestamp: number;
  originalStartTimestamp?: string;
  originalEndTimestamp?: string;
}

export interface InvoiceItemData extends Array<InvoiceItemDataAny> {}

export type InvoiceItemDataWeight = {
  type: "weight";
  value: number;
  unit: "grams" | "kilograms" | "tons";
};
export type InvoiceItemDataVolume = {
  type: "volume";
  value: number;
  unit: "milliliters" | "liters";
};
export type InvoiceItemDataDuration = {
  type: "duration";
  value: number;
  unit: "seconds" | "minutes" | "hours" | "days" | "weeks" | "months" | "years";
};
export type InvoiceItemDataDate = { type: "date"; timestamp: number };
export type InvoiceItemDataPeriod = {
  type: "period";
  startTimestamp: number;
  endTimestamp: number;
};
export type InvoiceItemDataDistance = {
  type: "distance";
  value: number;
  unit: "meters" | "kilometers";
};
export type InvoiceItemDataDomain = { type: "domain_name"; value: string };
export type InvoiceItemDataAddress = {
  type: "address";
  value: string;
};
//export type InvoiceItemDataCity= {type:"city",value:string};
export type InvoiceItemDataLegal = { type: "paragraph"; value: string };
export type InvoiceItemDataReservation = { type: "reservation"; id: string };

//primitives
export type InvoiceItemDataString = { type: "string"; value: string };
export type InvoiceItemDataNumber = { type: "number"; value: number };
export type InvoiceItemDataBoolean = { type: "boolean"; value: boolean };
//
export type InvoiceItemDataAny =
  | InvoiceItemDataComplex
  | InvoiceItemDataPrimitive;

export type InvoiceItemDataPrimitive =
  | InvoiceItemDataString
  | InvoiceItemDataNumber
  | InvoiceItemDataBoolean;
export type InvoiceItemDataComplex =
  | InvoiceItemDataReservation
  | InvoiceItemDataLegal
  | InvoiceItemDataPeriod
  | InvoiceItemDataDate
  | InvoiceItemDataAddress
  | InvoiceItemDataWeight
  | InvoiceItemDataVolume
  | InvoiceItemDataDuration
  | InvoiceItemDataDistance
  | InvoiceItemDataDomain
  | InvoiceItemDataAddress;
//
//descs
export const InvoiceItemDataWeightDesc =
  '{type:"weight",value:number,unit:"grams"|"kilograms"|"tons"} //e.g. {type:"weight",value:1,unit:"kilograms"}';
export const InvoiceItemDataVolumeDesc =
  '{type:"volume",value:number,unit:"milliliters"|"liters"} //e.g. {type:"volume",value:1,unit:"liters"}';
export const InvoiceItemDataDurationDesc =
  '{type:"duration",value:number,unit:"seconds"|"minutes"|"hours"|"days"|"weeks"|"months"|"years"} //e.g. {type:"duration",value:1,unit:"hours"}';
export const InvoiceItemDataDateDesc =
  '{type:"date",timestamp:number} //e.g. {type:"date",timestamp:1690820037}';
export const InvoiceItemDataPeriodDesc =
  '{type:"period",startTimestamp:number,endTimestamp:number} //e.g. {type:"period",startTimestamp:1690820037,endTimestamp:1690840037}';
export const InvoiceItemDataDistanceDesc =
  '{type:"distance",value:number,unit:"meters"|"kilometers"} //e.g. {type:"distance",value:1,unit:"kilometers"}';
export const InvoiceItemDataDomainDesc =
  '{type:"domain_name",value:string} //e.g. {type:"domain_name",value:"example.com"}';
export const InvoiceItemDataAddressDesc =
  '{type:"address",value:string} //e.g. {type:"address",value:"' +
  addressFormat +
  '"}';
//export const InvoiceItemDataCityDesc= '{type:"city",value:string} //e.g. {type:"city",value:"New York"}';
export const InvoiceItemDataLegalDesc =
  '{type:"legal",value:string} //e.g. {type:"legal",value:"...."}';
export const InvoiceItemDataReservationDesc =
  '{type:"reservation",id:string} //e.g. {type:"reservation",id:"...."}';

//primitives descs
export const InvoiceItemDataStringDesc = '{type:"string",value:string}';
export const InvoiceItemDataNumberDesc = '{type:"string",value:number}';
export const InvoiceItemDataBooleanDesc = '{type:"string",value:boolean}';

export const InvoiceItemDataComplexOnlyDesc = [
  InvoiceItemDataWeightDesc,
  InvoiceItemDataVolumeDesc,
  InvoiceItemDataDurationDesc,
  InvoiceItemDataDistanceDesc,
  InvoiceItemDataDomainDesc,
  InvoiceItemDataAddressDesc,
  InvoiceItemDataDateDesc,
  InvoiceItemDataPeriodDesc,
  InvoiceItemDataLegalDesc,
  InvoiceItemDataReservationDesc,
  //InvoiceItemDataCityDesc
];

export const InvoiceItemDataAllDesc = [
  InvoiceItemDataWeightDesc,
  InvoiceItemDataVolumeDesc,
  InvoiceItemDataDurationDesc,
  InvoiceItemDataDistanceDesc,
  InvoiceItemDataDomainDesc,
  InvoiceItemDataAddressDesc,
  InvoiceItemDataDateDesc,
  InvoiceItemDataPeriodDesc,
  InvoiceItemDataLegalDesc,
  InvoiceItemDataReservationDesc,
  //InvoiceItemDataCityDesc,
  //
  InvoiceItemDataStringDesc,
  InvoiceItemDataNumberDesc,
  InvoiceItemDataBooleanDesc,
];

export const SESSION_SEC = 7 * 24 * 60 * 60; //1 week
