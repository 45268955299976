import myFetch, { dispatchResponseError } from "./Helper";
import { Routes } from "../shared/Routes";
import { UserModel } from "models/UserModel";

export default class LogoutCmd {
  public async run(): Promise<void> {
    const route = Routes.logout;
    const response = await myFetch(route, {});
    if (!response) {
      return;
    }
    const json = await response.json();
    if (json.error) {
      dispatchResponseError(json.error);
    }
    if (json.data) {
      UserModel.instance.logout();
    }
  }
}
