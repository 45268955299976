import {
  Button,
  Checkbox,
  Colors,
  Divider,
  Icon,
  InputGroup,
  Menu,
  MenuItem,
  NumericInput,
  Popover,
  Tag,
  TagInput,
  TextArea,
} from "@blueprintjs/core";
import { DateInput3 } from "@blueprintjs/datetime2";
import InvoiceOperationCmd from "cmd/InvoiceOperationCmd";
import { useCallback, useEffect, useState } from "react";
import { InvoiceData, shareLink } from "shared";

export function EditInvoice(pars: { invoiceData: InvoiceData }) {
  const [data, setData] = useState<InvoiceData>(pars.invoiceData);

  const [showExtendedCustomerInput, setShowExtendedCustomerInput] =
    useState(false);

  const [loading, setLoading] = useState<"no" | "update" | "delete" | "rescan">(
    "no"
  );

  //console.log("EditInvoice", data);

  const onButtonRescan = async () => {
    setLoading("rescan");
    await new InvoiceOperationCmd().run(data.invoiceId, "rescan");
    setLoading("no");
  };

  const onButtonDelete = async () => {
    setLoading("delete");
    await new InvoiceOperationCmd().run(data.invoiceId, "delete");
    setLoading("no");
  };

  const onButtonUpdate = async () => {
    setLoading("update");
    await new InvoiceOperationCmd().run(data.invoiceId, "update",  data.parsedData);
    setLoading("no");
  };

  const issuer = data.parsedData.issuer;
  const customer = data.parsedData.customer;
  const invoiceNumber = data.parsedData.invoiceNumber;

  const desc = pars.invoiceData.parsedData.details
    .map((item) => {
      return item.contents;
    })
    .join("\n\n");

  const fullShareLink = shareLink.replace(
    ":shareId",
    pars.invoiceData.scanData.shareId
  );
  const onCopyJson=()=>{
    navigator.clipboard.writeText(JSON.stringify(pars.invoiceData.parsedData, null, 2));
  };
  return (
    <div>
      <Divider></Divider>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: "40%" }}>
          <LabeledInput
            label="Invoice number"
            content={invoiceNumber!}
            onChange={(n) => {
              setData((prev)=>{ prev.parsedData.invoiceNumber = n; return {...prev} });
            }}
          />
        </div>
        <div style={{ width: "60%" }}>
          <div style={{ display: "flex" }}>
            <LabeledDate
              timestamp={data.parsedData.dateIssued.timestamp!}
              label="Date issued"
              onChange={(n) => {
                setData((prev)=>{ prev.parsedData.dateIssued.timestamp = n; return {...prev} });
              }}
            />

            <LabeledDate
              timestamp={data.parsedData.datePaid.timestamp}
              label="Date paid"
              onChange={(n) => {
                setData((prev)=>{ prev.parsedData.datePaid.timestamp = n; return {...prev} });
              }}
            />

            <LabeledPriceInput
              onChange={(price: number, currency: string) => {
                setData((prev)=>{ prev.parsedData.fullPrice.gross = price; prev.parsedData.fullPrice.currencyCode=currency; return {...prev} });
              }}
              price={data.parsedData.fullPrice.gross}
              currency={data.parsedData.fullPrice.currencyCode}
            ></LabeledPriceInput>
          </div>
        </div>
      </div>

      <LabeledCheckbox
        url={fullShareLink}
        onChange={(n) => {
          setData((prev)=>{ prev.parsedData.public = n; return {...prev} });
        }}
        set={data.parsedData.public!}
      ></LabeledCheckbox>

      <LabeledTags
        tags={pars.invoiceData.parsedData.tags}
        onChange={(v) => {
          setData((prev)=>{ console.log("new tags",v); prev.parsedData.tags = v; return {...prev} });
        }}
      ></LabeledTags> 

      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div style={{ width: "100%" }}>
          <LabeledInput
            label="Issuer"
            content={issuer.name}
            onChange={(v) => {
              setData((prev)=>{ prev.parsedData.issuer.name = v; return {...prev} });
            }}
          />
          {showExtendedCustomerInput && (
            <LabeledInput
              label="Address"
              content={issuer.address}
              onChange={(v) => {
                setData((prev)=>{ prev.parsedData.issuer.address = v; return {...prev} });
              }}
            />
          )}
          {showExtendedCustomerInput && (
            <LabeledInput
              label="Tax number"
              content={issuer.taxNumber!}
              onChange={(v) => {
                setData((prev)=>{ prev.parsedData.issuer.taxNumber = v; return {...prev} });
              }}
            />
          )}
        </div>
        <div style={{ width: "100%" }}>
          <LabeledInput
            label="Customer"
            content={customer.name}
            onChange={(v) => {
              setData((prev)=>{ prev.parsedData.customer.name = v; return {...prev} });
            }}
          />
          {showExtendedCustomerInput && (
            <LabeledInput
              label="Address"
              content={customer.address}
              onChange={(v) => {
                setData((prev)=>{ prev.parsedData.customer.address = v; return {...prev} });
              }}
            />
          )}
          {showExtendedCustomerInput && (
            <LabeledInput
              label="Account number"
              content={customer.accountNumber}
              onChange={(v) => {
                setData((prev)=>{ prev.parsedData.customer.accountNumber = v; return {...prev} });
              }}
            />
          )}
        </div>
      </div>

      {showExtendedCustomerInput && (<div style={{ margin: 5 }}>
        <div style={{display:"flex"}}>
          <div style={{display:"block",marginTop:"auto",marginBottom:"auto"}}>JSON format</div>
          <Button onClick={onCopyJson} minimal icon="clipboard" small title="Copy JSON into clipboard"></Button>
        </div>
        <TextArea fill readOnly rows={5} style={{fontSize:10}}>
          {JSON.stringify(pars.invoiceData.parsedData, null, 2)}
        </TextArea>
      </div>)}

      <div style={{ display: "flex" }}>
        <Button
          fill
          icon={showExtendedCustomerInput ? "caret-up" : "caret-down"}
          minimal
          small
          onClick={() =>
            setShowExtendedCustomerInput(!showExtendedCustomerInput)
          }
        ></Button>
      </div>
      <Divider></Divider>
      <div style={{ display: "flex", color: Colors.GRAY2, margin: 5 }}>
        <Button
          intent="primary"
          loading={loading === "update"}
          onClick={onButtonUpdate}
        >
          Save changes
        </Button>
        <div style={{ width: 10 }}></div>
        <Button
          loading={loading === "rescan"}
          onClick={onButtonRescan}
          icon="refresh"
          text="Scan again"
        />
        <div style={{ width: 10 }}></div>
        <Button
          loading={loading === "delete"}
          onClick={onButtonDelete}
          icon="trash"
          text="Delete invoice"
          intent="danger"
        />
        <div
          style={{
            display: "block",
            marginTop: "auto",
            marginBottom: "auto",
            marginLeft: 10,
          }}
        >
          Filename: {data.scanData.fileName}
        </div>
      </div>
    </div>
  );
}

function LabeledTags(pars: {
  tags: Array<string>;
  onChange: (tags: Array<string>) => void;
}) {
  const [tags, setTags] = useState<Array<string>>(pars.tags);

  /*useEffect(() => {
    pars.onChange(tags);
  }, [pars, tags]);*/

  const tagsElements = tags.map((tag, index) => {
    return (
      <Tag key={index} active>
        {tag}
      </Tag>
    );
  });
  return (
    <div style={{ margin: 5 }}>
      <div style={{ display: "flex" }}>
        <div
          style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
        >
          Tags
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <TagInput
          fill
          values={tagsElements}
          onRemove={(val, index: number) => {
            setTags((prev) => {
              const copy = [...prev];
              copy.splice(index, 1);
              pars.onChange(copy);
              return copy;
            });
          }}
          onAdd={(t) => {
            setTags((prev) => {
              const newTags = [...prev, ...t];
              console.log("newTags", newTags);
              //return without duplicates
              const r= newTags.filter(
                (item, pos) => newTags.indexOf(item) === pos
              );
              pars.onChange(r);
              return r;
            });
          }}
        ></TagInput>
      </div>
    </div>
  );
}

function CurrencyDropdown(pars: {
  currency: string;
  onChange: (currency: string) => void;
}) {
  //const [currency, setCurrency] = useState<string>(pars.currency.toUpperCase());

  /*useEffect(() => {
    console.log("currency changed", currency);
    //pars.onChange(currency);
  }, [pars, currency]);*/

  const currencies = ["USD", "EUR", "GBP"];
  if (currencies.indexOf(pars.currency) === -1) {
    currencies.push(pars.currency);
  }
  const menu = () => {
    return (
      <Menu>
        {currencies.map((c, i) => {
          return (
            <MenuItem
              key={i}
              onClick={pars.onChange.bind(null, c)}
              text={c}
              active={pars.currency === c}
            ></MenuItem>
          );
        })}
      </Menu>
    );
  };
  return (
    <Popover content={menu()} placement="bottom">
      <Button text={pars.currency} />
    </Popover>
  );
}

function LabeledPriceInput(pars: {
  price: number;
  currency: string;
  onChange: (price: number, currency: string) => void;
}) {
  return (
    <div style={{ margin: 5 }}>
      <div style={{ display: "flex" }}>
        <div
          style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
        >
          Price (Gross)
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <NumericInput
          buttonPosition="none"
          placeholder="Enter here"
          onValueChange={(newPrice) => {
            pars.onChange(newPrice, pars.currency);
          }}
          type="number"
          fill
          value={pars.price}
        ></NumericInput>
        <CurrencyDropdown
          currency={pars.currency}
          onChange={(newCurrency) => {
            console.log("newCurrency", newCurrency);
            pars.onChange(pars.price, newCurrency);
          }}
        ></CurrencyDropdown>
      </div>
    </div>
  );
}

function LabeledInput(pars: {
  label: string;
  content: string;
  onChange: (value: string) => void;
}) {
  return (
    <div style={{ margin: 5 }}>
      <div style={{ display: "flex" }}>
        <div
          style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
        >
          {pars.label}
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <InputGroup
          placeholder="Enter here"
          onValueChange={pars.onChange}
          type="text"
          fill
          value={pars.content}
        ></InputGroup>
      </div>
    </div>
  );
}

function LabeledDate(pars: {
  label: string;
  timestamp: number;
  onChange: (value: number) => void;
}) {
  const d = new Date(pars.timestamp * 1000);
  const formatDate = useCallback((date: Date) => date.toLocaleDateString(), []);
  const parseDate = useCallback((str: string) => new Date(str), []);

  return (
    <div style={{ margin: 5 }}>
      <div style={{ display: "flex" }}>
        <div
          style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
        >
          {pars.label}
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <DateInput3
          fill
          formatDate={formatDate}
          onChange={(newDate) => {
            if (newDate) {
              pars.onChange(Date.parse(newDate) / 1000);
            } else {
              pars.onChange(0);
            }
          }}
          parseDate={parseDate}
          placeholder="M/D/YYYY"
          value={pars.timestamp === 0 ? "" : d.toISOString()}
        />
      </div>
    </div>
  );
}

function LabeledCheckbox(pars: {
  url: string;
  set: boolean;
  onChange: (value: boolean) => void;
}) {
  return (
    <div style={{ margin: 5 }}>
      <div style={{ display: "flex" }}>
        <Checkbox
          checked={pars.set}
          onChange={(e) => {
            pars.onChange(e.target.checked);
          }}
        ></Checkbox>
        <div style={{ color: pars.set ? Colors.BLACK : Colors.GRAY3 }}>
          Share URL:{" "}
          <a
            style={pars.set ? {} : { color: Colors.GRAY3 }}
            href={pars.url}
            target="_blank"
            rel="noreferrer"
          >
            {pars.url}
          </a>
        </div>
      </div>
    </div>
  );
}

function LabeledTextarea(pars: {
  label: string;
  content: string;
  onChange: (value: string) => void;
}) {
  return (
    <div style={{ margin: 5 }}>
      <div style={{ display: "flex" }}>
        <div
          style={{ display: "block", marginTop: "auto", marginBottom: "auto" }}
        >
          {pars.label}
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <TextArea
          maxLength={1024}
          placeholder="Enter here"
          fill
          value={pars.content}
          onChange={(e) => {
            pars.onChange(e.target.value);
          }}
        ></TextArea>
      </div>
    </div>
  );
}
