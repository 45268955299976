import { AllQueryJobs, PlanList } from "../shared";
import React, { useEffect } from "react";
import { Button, Callout, Card, Colors, Divider } from "@blueprintjs/core";
import { QueryJobsModel } from "../models/QueryJobsModel";
import { QueryRow } from "./query/QueryRow";
import { QueryInput } from "./query/QueryInput";
import DeleteScanJobCmd from "cmd/DeleteScanJobCmd";
import DeleteQueryJobCmd from "cmd/DeleteQueryJobCmd";
import MinuteIntervalCmd from "cmd/MinuteIntervalCmd";

export default function QueryPart() {
  const [jobs, setJobs] = React.useState<AllQueryJobs>(
    QueryJobsModel.instance.getQueryJobs()
  );

  useEffect(() => {
    const onJobUpdated = (e: Event) => {
      const ce = e as CustomEvent;
      setJobs({ ...(ce.detail as AllQueryJobs) });
    };
    document.addEventListener(
      QueryJobsModel.events.queryJobsChanged,
      onJobUpdated
    );
    return () => {
      document.removeEventListener(
        QueryJobsModel.events.queryJobsChanged,
        onJobUpdated
      );
    };
  }, []);

  const onDeleteAllQueries = () => {
    new DeleteQueryJobCmd().run();
  };

  const hasOutstanding = QueryJobsModel.instance.hasOutstandingQueryJobs();
  const sortedList = jobs.list.sort(
    (a, b) => b.createdTimestamp! - a.createdTimestamp!
  );

  return (
    <div
      style={{
        display: "block",
        width: "100%",
      }}
    >
      <QueryInput />
      <div style={{ height: 10 }}></div>
      <div>
        {jobs.list.map((j, i) => {
          return (
            <div key={i}>
              <QueryRow data={j} index={i} />
              <div style={{ height: 10 }} />
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {hasOutstanding && (
          <Button icon="refresh" minimal onClick={()=>{
            new MinuteIntervalCmd().run();
          }}>
            Refresh
          </Button>
        )}
        {jobs.list.length > 1 && (
          <Button onClick={onDeleteAllQueries} icon="trash" minimal>
            Clear queries
          </Button>
        )}
      </div>
    </div>
  );
}

