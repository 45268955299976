import myFetch, { dispatchResponseError } from "./Helper";
import * as INV from "../shared/Routes";
import { InvoicesModel } from "models/InvoicesModel";

export default class DeleteInvoicesCmd {
  public async run(invoiceIds?: Array<string>): Promise<void> {
    const route = INV.Routes.deleteallinvoices;
    InvoicesModel.instance.setInvoices([]);
    const response = await myFetch(route, { invoiceIds });
    if (!response) {
      return;
    }
    const json = await response.json();
    if (json.error) {
      dispatchResponseError(json.error);
    }
  }
}
