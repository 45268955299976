import { ScanJobsModel } from "models/ScanJobsModel";
import GetScanJobsCmd from "./GetScanJobsCmd";
import GetInvoicesCmd from "./GetInvoicesCmd";
import { UserModel } from "models/UserModel";
import { QueryJobsModel } from "models/QueryJobsModel";

let intervalID: any;

export default class MinuteIntervalCmd {
  public async run(): Promise<void> {
    clearInterval(intervalID);
    intervalID = setInterval(this.onMinute.bind(this), 10*1000);
  }

  private async onMinute() {
    if (UserModel.instance.fetching) {
      return;
    }
    await this.checkScans();
    await this.checkQueries();
  }

  private async checkQueries()
  {
    const outstandingJobsPresent = QueryJobsModel.instance.hasOutstandingQueryJobs();
    if (outstandingJobsPresent) {
      await new GetScanJobsCmd().run();
    }
  }

  private async checkScans() {
    const jobs = ScanJobsModel.instance.getOutstandingScanJobs();
    if (jobs.length > 0) {
      await new GetScanJobsCmd().run();
      const newJobs = ScanJobsModel.instance.getOutstandingScanJobs();
      if (newJobs.length !== jobs.length) {
        await new GetInvoicesCmd().run();
      }
    }
  }
}
